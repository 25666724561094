import * as convert from 'xml-js'

export enum AUTH_MODE {
    REGISTER = 'SignUp',
    LOGIN = 'Login',
    LOGOUT = 'Logout',
    CHANGE_PASSWORD = 'ChangePassword',
    RESET_PASSWORD = 'ResetPwd',
    EDIT = 'EditMemberData',
    SHOW = 'ShowMemberData',
    GROUPS = 'ReadGroups',
    BOOK_GROUP = 'BookGroup',
    QUIT_GROUP = 'QuitGroup',
    COURSES = 'ReadCourses',
    REGISTER_FOR_COURSES = 'RegisterForCourse',
}

const options = {
    compact: true,
    ignoreComment: true,
    ignoreDeclaration: false,
    spaces: 4,
    attributesKey: '@',
}

const transformToXml = (authMode: AUTH_MODE, user?: string, password?: string, token?: string): string => {
    const userPart = user ? { _text: user } : {}
    const passwordPart = password ? { _text: password } : {}
    const tokenPart = token ? { _text: token } : {}

    return convert.js2xml(
        {
            _declaration: {
                // @ts-ignore
                '@': {
                    version: '1.0',
                    encoding: 'utf-8',
                },
            },
            MemberLoginData: {
                RequestState: {},
                Errors: {},
                Mode: {
                    _text: authMode,
                },
                MemberData: {
                    Writable: {
                        Name: {},
                        Vorname: {},
                        Titel: {},
                        Anrede: {},
                        Geburtstag: {},
                        Geschlecht: {},
                        Strasse: {},
                        Hausnummer: {},
                        Co: {},
                        Plz: {},
                        Ort: {},
                        Telefon: {},
                        Mitgliedemail: userPart,
                        Iban: {},
                        Bic: {},
                        ID_Zahler: {},
                        Zname: {},
                        Zvorname: {},
                        Ztitel: {},
                        Zanrede: {},
                        Zstrasse: {},
                        Zhausnummer: {},
                        Zplz: {},
                        Zort: {},
                        VereinsAustritt: {},
                    },
                    Readonly: {
                        ID_Mitglied: {},
                        Faelligkeit: {},
                        Zahlungsart: {},
                        VereinsEintritt: {},
                        Beitraege: {},
                    },
                },
                LoginData: {
                    Passwort: passwordPart,
                    Token: tokenPart,
                },
            },
        },
        options,
    )
}

export default transformToXml
