import { action, thunk } from 'easy-peasy'
import { PaymentDataType } from 'shared_components/FormPaymentData/PaymentDataType'
import { PaymentDataModel } from './index.interface'

const paymentDataModel: PaymentDataModel = {
    paymentData: null,
    setPaymentData: action((state, payload) => {
        state.paymentData = payload
    }),
    getPaymentData: thunk(async (actions, _payload, { getStoreState }) => {
        const userData = getStoreState().authentication.user
        const memberIndex = getStoreState().memberIndexData.index

        if (userData?.MemberLoginData.MemberData) {
            // TODO select by laufende nummer
            const memberData = Array.isArray(userData?.MemberLoginData.MemberData)
                ? userData?.MemberLoginData.MemberData[memberIndex]
                : userData?.MemberLoginData.MemberData

            const hasDifferentAccountOwner = memberData.Writable.Zvorname._text ? true : false
            const sepaAccepted = memberData.Writable.Iban._text ? true : false

            const paymentData: PaymentDataType = {
                paymentData: {
                    iban: memberData.Writable.Iban._text || '',
                    bic: memberData.Writable.Bic._text || '',
                    hasDifferentAccountOwner: hasDifferentAccountOwner,
                    firstName: memberData.Writable.Zvorname._text || '',
                    lastName: memberData.Writable.Zname._text || '',
                    sepaAccepted: sepaAccepted,
                },
            }

            actions.setPaymentData(paymentData)
        }
    }),
    updatePaymentData: thunk(async (actions, payload, { getStoreState, injections }) => {
        const { paymentDataService } = injections
        const userEmail = getStoreState().authentication.userEmail
        const userToken = getStoreState().authentication.userToken
        const member = getStoreState().authentication.user?.MemberLoginData.MemberData
        const memberIndex = getStoreState().memberIndexData.index
        // TODO select by laufende nummer
        const memberData = Array.isArray(member) ? member[memberIndex] : member

        if (!memberData) {
            return
        }

        const newMemberData = await paymentDataService.updatePaymentData(payload, memberData, userEmail, userToken)
        return newMemberData
    }),
}

export default paymentDataModel
export type { PaymentDataModel }
