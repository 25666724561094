import * as React from 'react'
import { useState } from 'react'
import { Button, Table, Modal } from 'react-bootstrap'

interface ICancelActiveSport {
    show: boolean
    dictKey: string
    wasActive: boolean
    parent?: string | undefined
    sectionName: string
    setShowPopup: (show: boolean) => void
    cancelActiveSport: (dictKey: string, wasActive: boolean, parent?: string) => void
}

const CancelActiveSport = ({
    show,
    dictKey,
    wasActive,
    parent,
    sectionName,
    cancelActiveSport,
    setShowPopup,
}: ICancelActiveSport): JSX.Element => {
    const [showWait, setShowWait] = useState(false)
    const [showError, setShowError] = useState(false)

    const hideCancelDialog = () => {
        setShowPopup(false)
    }

    const hideErrorDialog = () => {
        setShowError(false)
    }

    const handleCancelActive = async () => {
        setShowWait(true)
        cancelActiveSport(dictKey, wasActive, parent)
    }

    return (
        <>
            <Modal
                show={showWait}
                onHide={() => {
                    /* do nothing */
                }}
            >
                <Modal.Header>
                    <Modal.Title>Mitgliedschaft kündigen</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Bitte warten während wir Ihre Anfrage bearbeiten...</p>
                </Modal.Body>
            </Modal>

            <Modal show={showError} onHide={hideErrorDialog}>
                <Modal.Header closeButton>
                    <Modal.Title>Mitgliedschaft kündigen</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Es ist ein Fehler aufgetreten.</p>
                    <p>Bitte melden Sie sich ab und wieder an.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="rounded-0" variant="primary" onClick={hideErrorDialog}>
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={show} onHide={hideCancelDialog}>
                <Modal.Header closeButton>
                    <Modal.Title>Austritt aus Sparte/ Abteilung bestätigen</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <h5>{sectionName}</h5>
                    <p>
                        Der Austritt aus einer Sparte/ Abteilung des Niendorfer TSV von 1919 e.V. kann laut
                        Vereinssatzung mit einer Frist von einen Monat zum Quartalsende erfolgen.
                    </p>
                    <Table responsive>
                        <tbody>
                            <tr>
                                <th>Austritt aus Sparte/ Abtlg. bis zum</th>
                                <th>Wirksam zum</th>
                            </tr>
                            <tr>
                                <td>28./29. Februar</td>
                                <td>31. März</td>
                            </tr>
                            <tr>
                                <td>31. Mai</td>
                                <td>30. Juni</td>
                            </tr>
                            <tr>
                                <td>31. August</td>
                                <td>30. September</td>
                            </tr>
                            <tr>
                                <td>30. November</td>
                                <td>31. Dezember</td>
                            </tr>
                        </tbody>
                    </Table>
                    <p>
                        Hiermit bestätige ich den fristgerechten und verbindlichen Austritt aus o.g. Sparte/ Abteilung.
                        Ich bleibe weiterhin Mitglied im Hauptverein.
                    </p>
                </Modal.Body>

                <Modal.Footer>
                    <Button className="rounded-0" variant="secondary" onClick={hideCancelDialog} disabled={showWait}>
                        Abbrechen
                    </Button>
                    <Button className="rounded-0" variant="primary" onClick={handleCancelActive} disabled={showWait}>
                        Austritt aus Sparte/ Abteilung
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default CancelActiveSport
