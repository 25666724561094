import transformToJson, { ArvResponse, MemberData, RequestState } from 'hooks/transformToJson'
import { buildCancelMembershipXml } from 'utils/apiRequests'

const apiUrl = process.env.REACT_APP_API_URL

class MembershipApi {
    public cancelMembership = async (
        cancel: boolean,
        memberData: MemberData,
        user: string,
        token: string,
    ): Promise<ArvResponse> => {
        return new Promise((resolve, reject) => {
            const dataAsXML = buildCancelMembershipXml(cancel, memberData, user, token)
            const headers = new Headers()

            fetch(`${apiUrl}`, {
                method: 'POST',
                body: dataAsXML,
                headers,
            })
                .then(this.handleErrors)
                .then((response) => response.text())
                .then((response) => {
                    const arvResponse = transformToJson(response)
                    if (arvResponse.MemberLoginData.RequestState._text === RequestState.SUCCEED) {
                        resolve(arvResponse)
                    } else {
                        throw arvResponse
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    private handleErrors(response: Response): Response {
        if (!response.ok) {
            throw Error(response.statusText)
        }

        return response
    }
}

export default new MembershipApi()
