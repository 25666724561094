import { Redirect, Route, RouteProps } from 'react-router-dom'
import { useTypedStoreState } from 'store/hooks'
import { UserRole } from 'types/user'

export type ProtectedRouteProps = {
    roles: UserRole[]
} & RouteProps

export default function ProtectedRoute({ ...routeProps }: ProtectedRouteProps): JSX.Element {
    const isAuthenticated = useTypedStoreState((state) => state.authentication.isAuthenticated)

    if (!isAuthenticated) {
        return <Redirect to={{ pathname: '/' }} />
    }

    return <Route {...routeProps} />
}
