import transformToJson, { ArvResponse, MemberData, RequestState } from 'hooks/transformToJson'
import { SportDataType } from 'shared_components/FormSportData'
import { buildEditSportXml } from 'utils/apiRequests'

// FIXME
const apiUrl = process.env.REACT_APP_API_URL

class SportDataApi {
    public updateSportData = async (
        sportData: SportDataType,
        memberData: MemberData,
        user: string,
        token: string,
    ): Promise<ArvResponse> => {
        return new Promise((resolve, reject) => {
            const dataAsXML = buildEditSportXml(sportData, memberData, user, token)

            const headers = new Headers()
            fetch(`${apiUrl}`, {
                method: 'POST',
                body: dataAsXML,
                headers,
            })
                .then(this.handleErrors)
                .then((response) => response.text())
                .then((response) => {
                    const arvResponse = transformToJson(response)
                    if (arvResponse.MemberLoginData.RequestState._text === RequestState.SUCCEED) {
                        resolve(arvResponse)
                    }
                    reject(Error(arvResponse.MemberLoginData.Errors?.UserErrMsg?._text))
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    private handleErrors(response: Response): Response {
        if (!response.ok) {
            throw Error(response.statusText)
        }

        return response
    }
}

export default new SportDataApi()
