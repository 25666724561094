import format from 'date-fns/format'

export const renderDateFromString = (date?: string): string => {
    if (!date) {
        return '-'
    }

    const jsDate = Date.parse(date)
    return format(jsDate, 'dd.MM.yyyy')
}
