import { ArvResponse, MemberData } from 'hooks/transformToJson'
import { SportDataType } from 'shared_components/FormSportData'
import SportDataApi from './SportDataApi'

export interface SportDataServiceInterface {
    updateSportData: (
        sportData: SportDataType,
        memberData: MemberData,
        user: string,
        token: string,
    ) => Promise<ArvResponse>
}

class sportDataService {
    public updateSportData = async (
        sportData: SportDataType,
        memberData: MemberData,
        user: string,
        token: string,
    ): Promise<ArvResponse> => {
        return await SportDataApi.updateSportData(sportData, memberData, user, token)
    }
}

export default new sportDataService()
