import transformToJson, { ArvResponse, MemberData, RequestState } from 'hooks/transformToJson'

import transformToXml, { AUTH_MODE } from 'hooks/useArvAuth/transformToXml'
import { registerForCourseXml } from 'utils/apiRequests'

const apiUrl = process.env.REACT_APP_API_URL

class CoursesDataApi {
    public getCoursesData = async (user: string, token: string): Promise<ArvResponse> => {
        return new Promise((resolve, reject) => {
            const dataAsXML = transformToXml(AUTH_MODE.COURSES, user, undefined, token)

            const headers = new Headers()

            fetch(`${apiUrl}`, {
                method: 'POST',
                body: dataAsXML,
                headers,
            })
                .then(this.handleErrors)
                .then((response) => response.text())
                .then((response) => {
                    const arvResponse = transformToJson(response)

                    if (arvResponse.MemberLoginData.RequestState._text === RequestState.SUCCEED) {
                        resolve(arvResponse)
                    }

                    reject(Error(arvResponse.MemberLoginData.Errors?.UserErrMsg?._text))
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    public registerForCourseData = async (
        memberData: MemberData,
        courseData: {
            ID: string
            date: string
        },
        user: string,
        token: string,
    ): Promise<ArvResponse> => {
        return new Promise((resolve, reject) => {
            const dataAsXML = registerForCourseXml(memberData, courseData, user, token)
            const headers = new Headers()

            fetch(`${apiUrl}`, {
                method: 'POST',
                body: dataAsXML,
                headers,
            })
                .then(this.handleErrors)
                .then((response) => {
                    console.log('2: ', response)
                    return response.text()
                })
                .then((response) => {
                    const arvResponse = transformToJson(response)
                    if (arvResponse.MemberLoginData.RequestState._text === RequestState.SUCCEED) {
                        resolve(arvResponse)
                    } else {
                        throw arvResponse
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    private handleErrors(response: Response): Response {
        if (!response.ok) {
            throw Error(response.statusText)
        }

        return response
    }
}

export default new CoursesDataApi()
