import env from '@beam-australia/react-env'

const configService = {
    validateConfig: (): void => {
        configService.dummy()
    },
    dummy: (): boolean => {
        if (env('DUMMY')) {
            console.log('this should not happen. remove dummy code')
        }
        return true
    },
}

export default configService
