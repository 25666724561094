import { action, thunk } from 'easy-peasy'
import { AuthenticationModel } from './index.interface'

const authenticationModel: AuthenticationModel = {
    isAuthenticated: false,
    user: null,
    userEmail: '',
    userToken: '',

    setIsAuthenticated: action((state, payload) => {
        state.isAuthenticated = payload
    }),
    setUser: action((state, payload) => {
        state.user = payload
    }),
    setUserEmail: action((state, payload) => {
        state.userEmail = payload
    }),
    setUserToken: action((state, payload) => {
        state.userToken = payload
    }),
    updateMemberLoginData: thunk(async (actions, payload, { getStoreState }) => {
        if (payload) {
            const { user } = getStoreState().authentication
            const memberData = user?.MemberLoginData.MemberData
            const newMemberData = payload.MemberLoginData.MemberData
            if (memberData && Array.isArray(memberData) && !Array.isArray(newMemberData)) {
                let foundIndex = -1
                memberData.forEach((member, index) => {
                    if (member.Readonly.ID_Mitglied._text == newMemberData.Readonly.ID_Mitglied._text) {
                        foundIndex = index
                    }
                })
                const newPayload = Object.assign({}, user)
                if (foundIndex >= 0 && Array.isArray(newPayload.MemberLoginData.MemberData)) {
                    newPayload.MemberLoginData.MemberData[foundIndex] = newMemberData
                }
                actions.setUser(newPayload)
            } else {
                actions.setUser(payload)
            }
        }
    }),
}

export default authenticationModel
export type { AuthenticationModel }
