import * as React from 'react'
import { Badge } from 'react-bootstrap'

import styles from './CourseData.module.scss'

type placesType = {
    freePlaces: string
    capacity?: string
}

const LAST_FREE_PLACES = 5

const GroupBadge = (places: placesType): JSX.Element => {
    const freePlaces = parseInt(places.freePlaces)
    const capacity = places.capacity ? parseInt(places.capacity) : null
    let variant = 'success'

    if (freePlaces > 0 && freePlaces < LAST_FREE_PLACES) variant = 'warning'
    else if (freePlaces === 0) variant = 'danger'

    return (
        <Badge variant={variant} className={styles.badge}>
            {freePlaces} {capacity && <>/ {capacity}</>}
        </Badge>
    )
}

export default GroupBadge
