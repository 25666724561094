import { thunk } from 'easy-peasy'
import { MembershipDataModel } from './index.interface'

const membershipDataModel: MembershipDataModel = {
    cancelMembership: thunk(async (_actions, payload, { getStoreState, injections }) => {
        const { membershipService } = injections
        const userEmail = getStoreState().authentication.userEmail
        const userToken = getStoreState().authentication.userToken
        const member = getStoreState().authentication.user?.MemberLoginData.MemberData
        const memberIndex = getStoreState().memberIndexData.index
        // TODO select by laufende nummer
        const memberData = Array.isArray(member) ? member[memberIndex] : member

        if (!memberData) {
            return
        }

        return await membershipService.cancelMembership(true, memberData, userEmail, userToken)
    }),
}

export default membershipDataModel
export type { MembershipDataModel }
