import { action, thunk } from 'easy-peasy'
import { BillingDataModel } from './index.interface'

const billingDataModel: BillingDataModel = {
    billingData: null,
    setBillingData: action((state, payload) => {
        state.billingData = payload
    }),
    getBillingData: thunk(async (actions, _payload, { getStoreState }) => {
        const userData = getStoreState().authentication.user
        const memberIndex = getStoreState().memberIndexData.index

        if (userData?.MemberLoginData.MemberData) {
            // TODO select by laufende nummer
            const memberData = Array.isArray(userData?.MemberLoginData.MemberData)
                ? userData?.MemberLoginData.MemberData[memberIndex]
                : userData?.MemberLoginData.MemberData

            // This is a quickfix for letting Beitrag always an array (not good, but works)
            if (!Array.isArray(memberData.Readonly.Beitraege.Beitrag)) {
                memberData.Readonly.Beitraege.Beitrag = [memberData.Readonly.Beitraege.Beitrag]
            }

            actions.setBillingData(memberData.Readonly)
        }
    }),
}

export default billingDataModel
export type { BillingDataModel }
