import { ArvResponse, MemberData } from 'hooks/transformToJson'
import CoursesDataApi from './CoursesDataApi'

export interface CoursesDataServiceInterface {
    getCoursesData: (user: string, token: string) => Promise<ArvResponse>
}

class CoursesDataService {
    public getCoursesData = async (user: string, token: string): Promise<ArvResponse> => {
        return await CoursesDataApi.getCoursesData(user, token)
    }

    public registerForCourseData = async (
        memberData: MemberData,
        courseData: {
            ID: string
            date: string
        },
        user: string,
        token: string,
    ): Promise<ArvResponse> => {
        return await CoursesDataApi.registerForCourseData(memberData, courseData, user, token)
    }
}

export default new CoursesDataService()
