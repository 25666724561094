import { Formik } from 'formik'
import { Button, Form } from 'react-bootstrap'
import * as Yup from 'yup'
import { toast } from 'react-toastify'
import useArvAuth from 'hooks/useArvAuth'
import { EmailInput, FormInputLabeled, PasswordInput, useIsMounted } from 'common'
import styles from 'shared_components/forms.module.scss'

type FormValues = {
    email: string
    password: string
    passwordRepeat: string
}

const FormRegister = (): JSX.Element => {
    const isMounted = useIsMounted()
    const { register } = useArvAuth()

    const validationSchema = Yup.object({
        email: Yup.string().email('Keine gültige E-Mail').required('Pflichtfeld'),
        password: Yup.string().min(8, 'Min. 8 Zeichen nötig').required('bitte ein Passwort eingeben'),
        passwordRepeat: Yup.string()
            .required('Pflichtfeld')
            .oneOf([Yup.ref('password'), null], 'Keine Übereinstimmung'),
    })

    const initialValues: FormValues = {
        email: '',
        password: '',
        passwordRepeat: '',
    }

    const handleRegistration = async (values: FormValues): Promise<boolean> => {
        try {
            await register(values.email, values.password)

            toast.info(
                <div data-testid="succeedToast">Erfolgreich registriert - bitte schauen Sie in Ihre E-Mails.</div>,
            )

            return true
        } catch (err) {
            toast.error(<div data-testid="failureToast">{(err as Error).message}</div>)

            return false
        }
    }

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (values, { setSubmitting, resetForm }): Promise<void> => {
                setSubmitting(true)

                const isSucceed = await handleRegistration(values)

                if (isMounted()) {
                    if (isSucceed) {
                        resetForm()
                    }
                    setSubmitting(false)
                }
            }}
        >
            {({ values, handleSubmit, handleReset, isSubmitting }): JSX.Element => (
                <Form onSubmit={handleSubmit} onReset={handleReset} data-testid="form" className={styles.form}>
                    <div className="mb-3">
                        <FormInputLabeled
                            controlId="email"
                            label="E-Mailadresse&nbsp;*"
                            align="start"
                            inputComponent={
                                <EmailInput
                                    name="email"
                                    placeholder="E-Mailadresse"
                                    value={values.email}
                                    className="email"
                                />
                            }
                        />
                    </div>
                    <div className="mb-3">
                        <FormInputLabeled
                            controlId="password"
                            label="Passwort&nbsp;*"
                            align="start"
                            inputComponent={
                                <PasswordInput
                                    name="password"
                                    placeholder="Passwort"
                                    value={values.password}
                                    className="password"
                                />
                            }
                        />
                    </div>
                    <div className="mb-3">
                        <FormInputLabeled
                            controlId="passwordRepeat"
                            label="Passwort wiederholen&nbsp;*"
                            align="start"
                            inputComponent={
                                <PasswordInput
                                    name="passwordRepeat"
                                    placeholder="Passwort wiederholen"
                                    value={values.passwordRepeat}
                                    className="passwordRepeat"
                                />
                            }
                        />
                    </div>
                    <p className="text-right mb-0">
                        <Button className="rounded-0" variant="primary" type="submit" disabled={isSubmitting}>
                            Jetzt registrieren
                        </Button>
                    </p>
                </Form>
            )}
        </Formik>
    )
}

export default FormRegister
