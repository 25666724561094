import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import reportWebVitals from './reportWebVitals'
import { ErrorBoundary } from 'react-error-boundary'
import { ToastContainer } from 'react-toastify'
import { BrowserRouter } from 'react-router-dom'
import { StoreProvider } from 'easy-peasy'
import store from 'store'
import configService from 'services/Config/configService'
import { ErrorFallback } from 'common'
import App from './App'
import './index.scss'

Sentry.init({
    dsn: 'https://92ee6bbdaa2c4afdb298513fb55d85bf@o173361.ingest.sentry.io/5832430',
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
})

const render = async (Component: React.FunctionComponent): Promise<void> => {
    if (process.env.MSW === 'on') {
        // eslint-disable-next-line
        const { worker } = require('./common/tests/server/browser')
        await worker.start()
    }

    try {
        configService.validateConfig()
    } catch (error) {
        ReactDOM.render(
            <ErrorFallback resetErrorBoundary={() => []} error={error as Error}></ErrorFallback>,
            document.getElementById('root'),
        )
        return
    }
    ReactDOM.render(
        <Sentry.ErrorBoundary fallback={ErrorBoundary}>
            <BrowserRouter>
                <StoreProvider store={store}>
                    <Component />
                </StoreProvider>
            </BrowserRouter>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={true}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                limit={5}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </Sentry.ErrorBoundary>,
        document.getElementById('root'),
    )
}

render(App)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
