import * as React from 'react'
import classNames from 'classnames'
import styles from './PersonalData.module.scss'
import { useState } from 'react'

interface Props {
    cardURL: string
}
const PersonalDataCard = ({ cardURL }: Props): JSX.Element => {
    const [isFullScreen, setIsFullScreen] = useState(false)

    const imgFullScreen = () => setIsFullScreen((prev) => !prev)

    return (
        <>
            <img
                src={cardURL}
                alt="id-card"
                className={classNames(styles.cardImg, {
                    [styles.cardFullScreen]: isFullScreen,
                })}
                onClick={imgFullScreen}
            />
            <div
                className={classNames(styles.cardText, {
                    [styles.cardTextFullScreen]: isFullScreen,
                })}
                onClick={imgFullScreen}
            >
                {isFullScreen ? 'Tap to close card to scan' : 'Tap to open card to scan'}
            </div>
            <div
                className={classNames(styles.cardFullScreenBg, {
                    [styles.show]: isFullScreen,
                })}
                onClick={imgFullScreen}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className={styles.closeIcon}
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                </svg>
            </div>
        </>
    )
}

export default PersonalDataCard
