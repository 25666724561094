export interface SportSectionEntry {
    [name: string]: string
}

// used for displaying the Name of the Section
export const sections: SportSectionEntry = {
    baseball: 'Baseball',
    basketball: 'Basketball',
    soccer: 'Fußball',
    handball: 'Handball',
    judo: 'Judo',
    jujutsu: 'JuJutsu',
    karate: 'Karate',
    athletics: 'Leichtathletik',
    walking: 'Walken',
    chess: 'Schach',
    swimming: 'Schwimmen',
    dancing: 'Tanzsport',
    tableTennis: 'Tischtennis',
    theatre: 'Theater/Laienspiel',
    volleyball: 'Volleyball',
    adyton: 'Adyton',
    tennis: 'Tennis',
    freeAthletics:
        'Turnen, Freizeit- und Gesundheitssport (z.B. Kinderturnen für Kinder über 4 Jahre, Seniorensport, BBP)',
    badminton: 'Badminton',
    kungfu: 'Kung-Fu',
    lungSport: 'Lungensport ohne Verordnung',
    krebsSport: 'Krebssport ohne Verordnung',
    osteoporose: 'Osteoporose',
    backFit: 'Rückenfit',
    trampoline: 'Trampolin',
    sportgymnastik: 'rhythmische Sportgymnastik',
    yoga: 'Yoga',
    childAthletics: 'Kinderturnen',
    onewheel: 'Einrad',
    hockey: 'Hockey',
    danceAddon_ballett: 'Tanzsport / Ballett',
    danceAddon_bellydance: 'Tanzsport / Bauchtanz',
    danceAddon_breakdance: 'Tanzsport / Breakdance',
    danceAddon_hiphop: 'Tanzsport / Hip Hop',
    danceAddon_contemporary: 'Tanzsport / Jazz / Modern+Contemporary',
    danceAddon_creativeChildrendance: 'Tanzsport / Kreativ. Kindertanz',
    danceAddon_linedance: 'Tanzsport / Linedance',
    danceAddon_pairdance: 'Tanzsport / Paartanz',
    danceAddon_tapdance: 'Tanzsport / Stepptanz',
}

// used for converting from section name to dance addon type
export const danceTypes: SportSectionEntry = {
    Ballett: 'danceAddon_ballett',
    Bauchtanz: 'danceAddon_bellydance',
    Breakdance: 'danceAddon_breakdance',
    'Hip Hop': 'danceAddon_hiphop',
    Contemporary: 'danceAddon_contemporary',
    'Kreativer Kindertanz': 'danceAddon_creativeChildrendance',
    'Line Dance': 'danceAddon_linedance',
    Paartanz: 'danceAddon_pairdance',
    Stepptanz: 'danceAddon_tapdance',
}
