import { createStore, persist } from 'easy-peasy'
import MembershipService, { MembershipServiceInterface } from 'services/membershipService/MembershipService'
import PaymentDataService, { PaymentDataServiceInterface } from 'services/paymentData/PaymentDataService'
import PersonalDataService, { PersonalDataServiceInterface } from 'services/PersonalData/PersonalDataService'
import SportDataService, { SportDataServiceInterface } from 'services/sportData/SportDataService'
import model from './model'

export interface Injections {
    personalDataService: PersonalDataServiceInterface
    paymentDataService: PaymentDataServiceInterface
    sportDataService: SportDataServiceInterface
    membershipService: MembershipServiceInterface
}

const store = createStore(persist(model), {
    injections: {
        personalDataService: PersonalDataService,
        paymentDataService: PaymentDataService,
        sportDataService: SportDataService,
        membershipService: MembershipService,
    },
    // dev tools are enabled by default -> also in production
    devTools: process.env.NODE_ENV === 'development',
})

if (process.env.NODE_ENV === 'development') {
    if (module.hot) {
        module.hot.accept('./model', () => {
            store.reconfigure(model)
        })
    }
}

export default store
