import { action, thunk } from 'easy-peasy'
import { BookGroupIndexModel } from './index.interface'

const bookGroupIndexModel: BookGroupIndexModel = {
    index: 0,
    setIndex: action((state, payload) => {
        state.index = payload
    }),
    getIndex: thunk((actions, _payload, { getState }) => {
        const index = getState().index
        return index
    }),
}

export default bookGroupIndexModel
export type { BookGroupIndexModel }
