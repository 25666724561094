import * as convert from 'xml-js'
import { AUTH_MODE } from './useArvAuth/transformToXml'

export enum RequestState {
    SUCCEED = 'OK',
    USER_ERROR = 'UserErr',
    TECH_ERROR = 'TechErr',
}

export type ArvText = { _text?: string }
export type ArvRequestState = { _text?: RequestState }
type ArvAuthMode = { _text?: AUTH_MODE }
export type ArvBeitrag = {
    Name: ArvText
    Betrag: ArvText
    ZahlungAb: ArvText
    ZahlungBis: ArvText
}
export type ArvErrors = {
    UserErrMsg?: ArvText
    TechErrCode?: ArvText
}

export type ArvSparten = {
    Sparte: [
        {
            Name: ArvText
            Eintritt: ArvText
            Austritt: ArvText
            SpartenID: ArvText
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            _attributes?: any
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            '@'?: any
        },
    ]
}

export type ArvMemberLoginGruppenDetails = {
    ID: { _text: string }
    Name: ArvText
    Writeliste: ArvText
}

export type ArvMemberLoginGruppen = {
    Gruppe: ArvMemberLoginGruppenDetails
}

export type ArvBookGroup = {
    Gruppe: [
        {
            ID: { _text: string }
            Writeliste: ArvText
        },
    ]
}

export type MemberData = {
    Writable: {
        Titel?: ArvText
        Anrede: ArvText
        Bic: ArvText
        Geburtstag: ArvText
        Hausnummer: ArvText
        Geschlecht: ArvText
        Co: ArvText
        ID_Zahler: ArvText
        Iban: ArvText
        Mitgliedemail: ArvText
        Name: ArvText
        Ort: ArvText
        Plz: ArvText
        Strasse: ArvText
        Telefon: ArvText
        VereinsAustritt: ArvText
        Vorname: ArvText
        Zanrede: ArvText
        Zhausnummer: ArvText
        Zname: ArvText
        Zort: ArvText
        Zplz: ArvText
        Zstrasse: ArvText
        Ztitel: ArvText
        Zvorname: ArvText
        Sparten?: ArvSparten
        GruppenBuchungen?: ArvBookGroup
    }
    Readonly: {
        Beitraege: {
            Beitrag: ArvBeitrag[]
        }
        Faelligkeit: ArvText
        ID_Mitglied: ArvText
        VereinsEintritt: ArvText
        Zahlungsart: ArvText
        MitgliedsNr: ArvText
        LfdNr: ArvText
        Gruppen: ArvMemberLoginGruppen
        DigitalerAusweis: ArvText
        Reservierungen: ArvReservierungen | ArvReservierungen[]
    }
}

export type ArvCourseDetails = {
    ID_Kurstermin: ArvText
    KursName: ArvText
    Datum: ArvText
    StartZeit: ArvText
    EndZeit: ArvText
    FreiePlaetze?: ArvText
    Kapazitaet?: ArvText
    Sportstaette?: ArvText
    isSignUp: boolean
}

export type ArvCourse = {
    Kurstermin: ArvCourseDetails | ArvCourseDetails[]
}

export type ArvGruppeDetails = {
    ID: ArvText
    GruppenName: ArvText
    SpartenName: ArvText
    FreiePlaetze: ArvText
    Kapazitaet: ArvText
    isSignUp?: boolean
}

export type ArvGruppe = {
    Gruppe: ArvGruppeDetails | ArvGruppeDetails[]
}

export type ArvReservierung = {
    ID_Kurstermin: ArvText
    Datum: ArvText
    Beschreibung: ArvText
}

export type ArvReservierungen = {
    Reservierung: ArvReservierung | ArvReservierung[]
}

export type ArvResponse = {
    MemberLoginData: {
        RequestState: ArvRequestState
        Errors?: ArvErrors
        Mode: ArvAuthMode
        MemberData: MemberData | MemberData[]
        Gruppen: ArvGruppe
        Kurstermine: {
            Kurstermin: {
                Datum: ArvText
                EndZeit: ArvText
                FreiePlaetze: ArvText
                ID_Kurstermin: ArvText
                KursName: ArvText
                StartZeit: ArvText
            }[]
        }
        LoginData: {
            Passwort?: ArvText
            Token?: ArvText
        }
        Reservierungen: ArvReservierungen | ArvReservierungen[]
    }
}

const options: convert.Options.XML2JS = {
    compact: true,
    ignoreComment: true,
    ignoreDeclaration: true,
    nativeType: false, // switch to false, so all numbers are strings
    trim: true,
}

const transformToJson = (xml: string): ArvResponse => {
    return convert.xml2js(xml, options) as ArvResponse
}

export default transformToJson
