import { action, thunk } from 'easy-peasy'
import { CourseDataModel } from './index.interface'
import CoursesDataService from 'services/coursesData/CoursesDataService'
import { ArvCourse, ArvCourseDetails, ArvReservierung, ArvReservierungen } from '../../../hooks/transformToJson'

const courseDataModel: CourseDataModel = {
    courseData: null,
    setCourseData: action((state, payload) => {
        state.courseData = payload
    }),
    registerForCourseData: thunk(async (actions, payload, { getStoreState }) => {
        const userEmail = getStoreState().authentication.userEmail
        const userToken = getStoreState().authentication.userToken
        const member = getStoreState().authentication.user?.MemberLoginData.MemberData
        const memberIndex = getStoreState().memberIndexData.index

        const memberData = Array.isArray(member) ? member[memberIndex] : member

        if (memberData && userEmail && userToken) {
            const data = await CoursesDataService.registerForCourseData(memberData, payload, userEmail, userToken)
            return data
        }
    }),
    getCourseData: thunk(async (actions, payload, { getStoreState }) => {
        const userEmail = getStoreState().authentication.userEmail
        const userToken = getStoreState().authentication.userToken
        if (userEmail && userToken) {
            const data = CoursesDataService.getCoursesData(userEmail, userToken)
            const member = getStoreState().authentication.user?.MemberLoginData.MemberData
            const memberIndex = getStoreState().memberIndexData.index

            const memberData = Array.isArray(member) ? member[memberIndex] : member

            const isSignUpHandler = (courses: ArvCourse, memberCourses: ArvReservierungen | undefined) => {
                const _singUpGroups: string[] = []

                if (memberCourses) {
                    if (memberCourses.Reservierung instanceof Array) {
                        memberCourses.Reservierung.map((course: ArvReservierung) =>
                            // @ts-ignore
                            _singUpGroups.push(course.ID_Kurstermin._text?.toLowerCase()),
                        )
                    } else if (memberCourses.Reservierung?.ID_Kurstermin) {
                        // @ts-ignore
                        _singUpGroups.push(memberCourses.Reservierung.ID_Kurstermin._text?.toLowerCase())
                    }
                }

                // @ts-ignore
                const _courses = courses.Kurstermin.map((course: ArvCourseDetails) => {
                    // @ts-ignore
                    if (_singUpGroups.includes(course.ID_Kurstermin._text)) {
                        course.isSignUp = true
                    }

                    return course
                })
                return _courses
            }

            await data.then((res) => {
                // @ts-ignore
                const courses = isSignUpHandler(res.MemberLoginData.Kurstermine, memberData?.Readonly.Reservierungen)
                actions.setCourseData(courses)
                return res
            })
        }
    }),
}

export default courseDataModel
export type { CourseDataModel }
