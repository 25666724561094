import * as React from 'react'
import { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useTypedStoreActions } from 'store/hooks'
import { ArvCourseDetails } from 'hooks/transformToJson'
import { format } from 'date-fns'
import { de } from 'date-fns/locale'

import styles from './CourseData.module.scss'

interface ICourseDataModal {
    show: boolean
    course: ArvCourseDetails | undefined
    setShowPopup: (show: boolean) => void
    setRefresh: (arg: boolean) => void
}

const AddCourseDataModal = ({ show, course, setRefresh, setShowPopup }: ICourseDataModal): JSX.Element => {
    const [showWait, setShowWait] = useState(false)

    const registerForCourseData = useTypedStoreActions((state) => state.courseData.registerForCourseData)
    const updateMemberLoginData = useTypedStoreActions((state) => state.authentication.updateMemberLoginData)

    const hideCancelDialog = () => setShowPopup(false)

    // @ts-ignore
    const date: string = typeof course !== 'undefined' ? course.Datum?._text : ''
    const dateFormat = format(new Date(date), 'iiii - dd.MM.yyyy', { locale: de })
    const timeFormat =
        typeof course !== 'undefined'
            ? `${course.StartZeit?._text?.slice(0, -3)} - ${course.EndZeit?._text?.slice(0, -3)}`
            : ''

    const handleBookGroup = async () => {
        setShowWait(true)
        if (course && course.ID_Kurstermin._text && course.Datum._text) {
            const courseData = {
                ID: course.ID_Kurstermin._text,
                date: course.Datum._text,
            }
            const result = await registerForCourseData(courseData)
            setShowPopup(false)
            setShowWait(false)
            updateMemberLoginData(result)
            setRefresh(true)
        }
    }

    return (
        <>
            <Modal show={show} onHide={hideCancelDialog}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {course?.KursName._text}
                        <div className={styles.subtitle}>
                            {dateFormat}, {timeFormat}
                        </div>
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>Ich melde hiermit mich/mein Kind für die o.g. Gruppe an.</Modal.Body>

                <Modal.Footer>
                    <Button className="rounded-0" variant="secondary" onClick={hideCancelDialog} disabled={showWait}>
                        Abbrechen
                    </Button>
                    <Button className="rounded-0" variant="primary" onClick={handleBookGroup} disabled={showWait}>
                        Gruppenauswahl verbindlich bestätigen
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default AddCourseDataModal
