import { ArvResponse, MemberData } from 'hooks/transformToJson'
import GroupsDataApi from './GroupsDataApi'

export interface GroupsDataServiceInterface {
    getGroupsData: (user: string, token: string) => Promise<ArvResponse>
}

class GroupsDataService {
    public getGroupsData = async (user: string, token: string): Promise<ArvResponse> => {
        return await GroupsDataApi.getGroupsData(user, token)
    }

    public bookGroupData = async (
        memberData: MemberData,
        bookGroup: string,
        user: string,
        token: string,
    ): Promise<ArvResponse> => {
        return await GroupsDataApi.bookGroupData(memberData, bookGroup, user, token)
    }

    public leaveGroupData = async (
        memberData: MemberData,
        bookGroup: string,
        user: string,
        token: string,
    ): Promise<ArvResponse> => {
        return await GroupsDataApi.leaveGroupData(memberData, bookGroup, user, token)
    }
}

export default new GroupsDataService()
