import * as React from 'react'
import { useState } from 'react'
import { ArvCourseDetails } from 'hooks/transformToJson'
import { Table } from 'react-bootstrap'
import { useTypedStoreActions } from 'store/hooks'
import { format } from 'date-fns'
import { de } from 'date-fns/locale'

import AddCourseDataModal from './AddCourseDataModal'
import CourseBookBtn from './CourseBookBtn'
import CourseBadge from './CourseBadge'

import styles from './CourseData.module.scss'

export interface Props {
    courses: ArvCourseDetails[]
}

const CourseData = ({ courses }: Props): JSX.Element => {
    const [showAddPopup, setShowAddPopup] = useState(false)
    const [_course, setCourse] = useState()
    const [refresh, setRefresh] = useState(false)
    const getCourseData = useTypedStoreActions((state) => state.courseData.getCourseData)

    const refreshGroupData = () => {
        if (!refresh) return
        getCourseData()
        setRefresh(false)
    }

    React.useEffect(() => refreshGroupData(), [refresh])

    return (
        <>
            <Table className={styles.table} responsive>
                <tbody>
                    <tr>
                        <th>KursName</th>
                        <th align="center">Datum</th>
                        <th align="center">Sportstaette</th>
                        <th align="center">Freie Plätze</th>
                        <th></th>
                    </tr>
                    {courses?.map(
                        (course: ArvCourseDetails, key: number): JSX.Element => (
                            <tr key={key}>
                                <td>{course.KursName?._text}</td>
                                <td align="center">
                                    {/*@ts-ignore*/}
                                    {format(new Date(course.Datum?._text), 'iiii - dd.MM.yyyy', { locale: de })}
                                    <br />
                                    {course.StartZeit?._text?.slice(0, -3)} - {course.EndZeit?._text?.slice(0, -3)}
                                </td>
                                <td align="center">{course.Sportstaette?._text}</td>
                                {course.FreiePlaetze?._text && course.Kapazitaet?._text && (
                                    <td align="center">
                                        <CourseBadge
                                            freePlaces={course.FreiePlaetze._text}
                                            capacity={course.Kapazitaet._text}
                                        />
                                    </td>
                                )}

                                <td align="right">
                                    {course.isSignUp ? (
                                        <div>Erfolgreich angemeldet</div>
                                    ) : (
                                        <CourseBookBtn
                                            course={course}
                                            setCourse={setCourse}
                                            setShowAddPopup={setShowAddPopup}
                                        />
                                    )}
                                </td>
                            </tr>
                        ),
                    )}
                </tbody>
            </Table>
            {_course && (
                <AddCourseDataModal
                    show={showAddPopup}
                    setShowPopup={setShowAddPopup}
                    course={_course}
                    setRefresh={setRefresh}
                />
            )}
        </>
    )
}

export default CourseData
