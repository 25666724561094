import { action, thunk } from 'easy-peasy'
import { MemberIndexModel } from './index.interface'

const memberIndexModel: MemberIndexModel = {
    index: 0,
    setIndex: action((state, payload) => {
        state.index = payload
    }),
    getIndex: thunk((actions, _payload, { getState }) => {
        const index = getState().index
        return index
    }),
}

export default memberIndexModel
export type { MemberIndexModel }
