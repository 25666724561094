import { action, thunk } from 'easy-peasy'
import { GroupDataModel } from './index.interface'
import GroupsDataService from 'services/groupsData/GroupsDataService'
import { ArvGruppe, ArvGruppeDetails, ArvMemberLoginGruppen } from 'hooks/transformToJson'

const FILTER_LIST = ['Kinderturnen']

const groupDataModel: GroupDataModel = {
    groupData: null,
    setGroupData: action((state, payload) => {
        state.groupData = payload
    }),
    bookGroupData: thunk(async (actions, payload, { getStoreState }) => {
        const userEmail = getStoreState().authentication.userEmail
        const userToken = getStoreState().authentication.userToken
        const member = getStoreState().authentication.user?.MemberLoginData.MemberData
        const memberIndex = getStoreState().memberIndexData.index

        const memberData = Array.isArray(member) ? member[memberIndex] : member

        if (memberData && userEmail && userToken) {
            const data = await GroupsDataService.bookGroupData(memberData, payload, userEmail, userToken)
            return data
        }
    }),
    leaveGroupData: thunk(async (actions, payload, { getStoreState }) => {
        const userEmail = getStoreState().authentication.userEmail
        const userToken = getStoreState().authentication.userToken
        const member = getStoreState().authentication.user?.MemberLoginData.MemberData
        const memberIndex = getStoreState().memberIndexData.index

        const memberData = Array.isArray(member) ? member[memberIndex] : member

        if (memberData && userEmail && userToken) {
            const data = await GroupsDataService.leaveGroupData(memberData, payload, userEmail, userToken)
            return data
        }
    }),
    filterGroupData: thunk(async (actions, payload, { getStoreState }) => {
        let groups = getStoreState().groupData.groupData

        groups =
            groups &&
            groups.filter((group: ArvGruppeDetails) => {
                const groupNameLowerCase = group.GruppenName._text?.toLowerCase()
                // @ts-ignore
                return groupNameLowerCase && groupNameLowerCase.includes(FILTER_LIST.map((f) => f.toLowerCase()))
            })
        groups && actions.setGroupData(groups)
    }),
    getGroupData: thunk(async (actions, payload, { getStoreState }) => {
        const userEmail = getStoreState().authentication.userEmail
        const userToken = getStoreState().authentication.userToken
        if (userEmail && userToken) {
            const data = GroupsDataService.getGroupsData(userEmail, userToken)
            const member = getStoreState().authentication.user?.MemberLoginData.MemberData
            const memberIndex = getStoreState().memberIndexData.index

            const memberData = Array.isArray(member) ? member[memberIndex] : member

            const isSignUpHandler = (groups: ArvGruppe[], memberGroups: ArvMemberLoginGruppen | undefined) => {
                const _singUpGroups: string[] = []

                if (memberGroups?.Gruppe instanceof Array) {
                    memberGroups?.Gruppe.map((group) => _singUpGroups.push(group.ID._text))
                } else if (memberGroups && memberGroups.Gruppe?.ID) {
                    _singUpGroups.push(memberGroups.Gruppe.ID._text)
                }
                // @ts-ignore
                const _groups = groups.Gruppe.map((group: ArvGruppeDetails) => {
                    if (group?.ID?._text && _singUpGroups.includes(group.ID._text)) {
                        group.isSignUp = true
                    }

                    return group
                })

                return _groups
            }

            await data.then((res) => {
                // @ts-ignore
                const groups = isSignUpHandler(res?.MemberLoginData.Gruppen, memberData?.Readonly.Gruppen)

                actions.setGroupData(groups)
                return res
            })
        }
    }),
}

export default groupDataModel
export type { GroupDataModel }
