import * as Sentry from '@sentry/react'
import transformToXml, { AUTH_MODE } from './transformToXml'
import transformToJson, { RequestState, ArvResponse, ArvErrors, ArvRequestState } from 'hooks/transformToJson'

const apiUrl = process.env.REACT_APP_API_URL

type Hook = {
    register: (user: string, password: string) => Promise<ArvResponse>
    login: (user: string, password: string) => Promise<ArvResponse>
    logout: (user: string, token: string) => Promise<null>
    resetPassword: (user: string, password: string) => Promise<null>
    changePassword: (newPassword: string, token: string) => Promise<null>
    refreshToken: (user: string, token: string) => Promise<null>
}

function handleErrors(response: Response): Response {
    if (!response.ok) {
        throw Error(response.statusText)
    }

    return response
}

const getErrorMessage = (arvErrors?: ArvErrors): string => {
    return JSON.stringify(arvErrors, null, 2)
}

const getRequestState = (arvRequestState?: ArvRequestState): string => {
    return JSON.stringify(arvRequestState, null, 2)
}

const useLogin = (): Hook => {
    const register = (user: string, password: string): Promise<ArvResponse> => {
        return new Promise((resolve, reject) => {
            const dataAsXML = transformToXml(AUTH_MODE.REGISTER, user, password, undefined)

            const headers = new Headers()

            fetch(`${apiUrl}`, {
                method: 'POST',
                body: dataAsXML,
                headers,
            })
                .then(handleErrors)
                .then((response) => response.text())
                .then((response) => {
                    const arvResponse = transformToJson(response)

                    if (arvResponse.MemberLoginData.RequestState._text === RequestState.SUCCEED) {
                        return resolve(arvResponse)
                    }

                    if (arvResponse.MemberLoginData.RequestState._text !== RequestState.USER_ERROR) {
                        Sentry.captureMessage(
                            `Register failed due to: ${getErrorMessage(
                                arvResponse.MemberLoginData.Errors,
                            )} with RequestState ${getRequestState(arvResponse.MemberLoginData.RequestState)}`,
                            Sentry.Severity.Error,
                        )
                    }

                    reject(Error(arvResponse.MemberLoginData.Errors?.UserErrMsg?._text))
                })
                .catch((error) => {
                    Sentry.captureException(error, { level: Sentry.Severity.Error, extra: { user } })
                    reject(error)
                })
        })
    }

    const login = (user: string, password: string): Promise<ArvResponse> => {
        return new Promise((resolve, reject) => {
            const dataAsXML = transformToXml(AUTH_MODE.LOGIN, user, password, undefined)

            const headers = new Headers()

            fetch(`${apiUrl}`, {
                method: 'POST',
                body: dataAsXML,
                headers,
            })
                .then(handleErrors)
                .then((response) => response.text())
                .then((response) => {
                    const arvResponse = transformToJson(response)

                    if (arvResponse.MemberLoginData.RequestState._text === RequestState.SUCCEED) {
                        return resolve(arvResponse)
                    }

                    if (arvResponse.MemberLoginData.RequestState._text !== RequestState.USER_ERROR) {
                        Sentry.captureMessage(
                            `Login failed due to: ${getErrorMessage(
                                arvResponse.MemberLoginData.Errors,
                            )} for user ${user} with RequestState ${getRequestState(
                                arvResponse.MemberLoginData.RequestState,
                            )}`,
                            Sentry.Severity.Error,
                        )
                    }

                    if (arvResponse.MemberLoginData.RequestState._text === RequestState.USER_ERROR) {
                        reject(Error(arvResponse.MemberLoginData.Errors?.UserErrMsg?._text))
                    } else {
                        reject(Error('Wir haben ein technisches Problem. Bitte kontaktieren Sie uns telefonisch.'))
                    }
                })
                .catch((error) => {
                    Sentry.captureException(error, { level: Sentry.Severity.Error, extra: { user } })
                    reject(error)
                })
        })
    }

    const logout = (user: string, token: string): Promise<null> => {
        return new Promise((resolve) => {
            const dataAsXML = transformToXml(AUTH_MODE.LOGOUT, user, undefined, token)

            const headers = new Headers()

            fetch(`${apiUrl}`, {
                method: 'POST',
                body: dataAsXML,
                headers,
            })
                .then(handleErrors)
                .then((response) => response.text())
                .then((response) => {
                    const arvResponse = transformToJson(response)

                    if (arvResponse.MemberLoginData.RequestState._text === RequestState.SUCCEED) {
                        return resolve(null)
                    }

                    // ignore session expired
                    if (
                        arvResponse.MemberLoginData.RequestState._text === RequestState.TECH_ERROR &&
                        arvResponse.MemberLoginData.Errors?.TechErrCode?._text == '2'
                    ) {
                        return resolve(null)
                    }

                    if (arvResponse.MemberLoginData.Errors) {
                        Sentry.captureMessage(
                            `Logout failed due to: ${getErrorMessage(
                                arvResponse.MemberLoginData.Errors,
                            )} with RequestState ${getRequestState(arvResponse.MemberLoginData.RequestState)}`,
                            Sentry.Severity.Info,
                        )
                    }

                    resolve(null)
                })
                .catch((error) => {
                    Sentry.captureException(error, { level: Sentry.Severity.Info, extra: { user } })
                    resolve(null)
                })
        })
    }

    const resetPassword = (user: string, password: string): Promise<null> => {
        return new Promise((resolve, reject) => {
            const dataAsXML = transformToXml(AUTH_MODE.RESET_PASSWORD, user, password, undefined)

            const headers = new Headers()

            fetch(`${apiUrl}`, {
                method: 'POST',
                body: dataAsXML,
                headers,
            })
                .then(handleErrors)
                .then((response) => response.text())
                .then((response) => {
                    const arvResponse = transformToJson(response)

                    if (arvResponse.MemberLoginData.RequestState._text === RequestState.SUCCEED) {
                        return resolve(null)
                    }

                    Sentry.captureMessage(
                        `Reset password failed due to: ${getErrorMessage(
                            arvResponse.MemberLoginData.Errors,
                        )} with RequestState ${getRequestState(arvResponse.MemberLoginData.RequestState)}`,
                        Sentry.Severity.Error,
                    )

                    if (arvResponse.MemberLoginData.RequestState._text === RequestState.USER_ERROR) {
                        reject(Error(arvResponse.MemberLoginData.Errors?.UserErrMsg?._text))
                    } else {
                        reject(Error('Wir haben ein technisches Problem. Bitte kontaktieren Sie uns telefonisch.'))
                    }
                })
                .catch((error) => {
                    Sentry.captureException(error, { level: Sentry.Severity.Error, extra: { user } })
                    reject(error)
                })
        })
    }

    const changePassword = (newPassword: string, token: string): Promise<null> => {
        return new Promise((resolve, reject) => {
            const dataAsXML = transformToXml(AUTH_MODE.CHANGE_PASSWORD, undefined, newPassword, token)

            const headers = new Headers()

            fetch(`${apiUrl}`, {
                method: 'POST',
                body: dataAsXML,
                headers,
            })
                .then(handleErrors)
                .then((response) => response.text())
                .then((response) => {
                    const arvResponse = transformToJson(response)

                    if (arvResponse.MemberLoginData.RequestState._text === RequestState.SUCCEED) {
                        return resolve(null)
                    }

                    if (arvResponse.MemberLoginData.RequestState._text === RequestState.TECH_ERROR) {
                        if (arvResponse.MemberLoginData.Errors?.TechErrCode?._text == '2') {
                            return reject(Error('Ihre Session ist abgelaufen.'))
                        }

                        Sentry.captureMessage(
                            `Change password failed due to: ${getErrorMessage(
                                arvResponse.MemberLoginData.Errors,
                            )} with RequestState ${getRequestState(arvResponse.MemberLoginData.RequestState)}`,
                            Sentry.Severity.Error,
                        )

                        if (arvResponse.MemberLoginData.Errors?.TechErrCode?._text == '1') {
                            return reject(
                                Error('Wir haben ein technisches Problem. Bitte kontaktieren Sie uns telefonisch.'),
                            )
                        }

                        return reject(Error('Es ist ein interner Fehler aufgetreten.'))
                    }

                    Sentry.captureMessage(
                        `Change password failed due to: ${getErrorMessage(
                            arvResponse.MemberLoginData.Errors,
                        )} with RequestState ${getRequestState(arvResponse.MemberLoginData.RequestState)}`,
                        Sentry.Severity.Error,
                    )
                    reject(Error(arvResponse.MemberLoginData.Errors?.UserErrMsg?._text))
                })
                .catch((error) => {
                    Sentry.captureException(error, { level: Sentry.Severity.Error })
                    reject(error)
                })
        })
    }

    const refreshToken = (user: string, token: string): Promise<null> => {
        return new Promise((resolve) => {
            const dataAsXML = transformToXml(AUTH_MODE.SHOW, user, undefined, token)

            const headers = new Headers()

            fetch(`${apiUrl}`, {
                method: 'POST',
                body: dataAsXML,
                headers,
            })
                .then(handleErrors)
                .then(() => {
                    return resolve(null)
                })
                .catch((error) => {
                    Sentry.captureException(error, { level: Sentry.Severity.Error, extra: { user } })
                    resolve(null)
                })
        })
    }

    return {
        register,
        login,
        logout,
        resetPassword,
        changePassword,
        refreshToken,
    }
}

export default useLogin
