import { ArvResponse, MemberData } from 'hooks/transformToJson'
import MembershipApi from './MembershipApi'

export interface MembershipServiceInterface {
    cancelMembership: (cancel: boolean, memberData: MemberData, user: string, token: string) => Promise<ArvResponse>
}

class membershipService {
    public cancelMembership = async (
        cancel: boolean,
        memberData: MemberData,
        user: string,
        token: string,
    ): Promise<ArvResponse> => {
        return await MembershipApi.cancelMembership(cancel, memberData, user, token)
    }
}

export default new membershipService()
