import { ArvBeitrag, ArvText } from 'hooks/transformToJson'

export enum FormMode {
    VIEW = 'view',
    EDIT = 'edit',
}

export type BillingData = {
    Faelligkeit: ArvText
    ID_Mitglied: ArvText
    VereinsEintritt: ArvText
    Zahlungsart: ArvText
    Beitraege: {
        Beitrag: ArvBeitrag[]
    }
}
