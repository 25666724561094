import endOfMonth from 'date-fns/endOfMonth'
import { action, thunk } from 'easy-peasy'
import { InitialValuesSportData, SportDataType } from 'shared_components/FormSportData'
import { danceTypes } from 'shared_components/sections'
import { SportDataModel } from './index.interface'

type JsonData = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any
}

const sportDataModel: SportDataModel = {
    sportData: null,
    setSportData: action((state, payload) => {
        state.sportData = payload
    }),
    getSportData: thunk(async (actions, _payload, { getStoreState }) => {
        const userData = getStoreState().authentication.user
        const memberIndex = getStoreState().memberIndexData.index

        if (userData?.MemberLoginData.MemberData) {
            // TODO select by laufende nummer
            const memberData = Array.isArray(userData?.MemberLoginData.MemberData)
                ? userData?.MemberLoginData.MemberData[memberIndex]
                : userData?.MemberLoginData.MemberData

            const sections = Array.isArray(memberData.Writable.Sparten?.Sparte)
                ? memberData.Writable.Sparten?.Sparte
                : [memberData.Writable.Sparten?.Sparte]

            let activeBranches: JsonData = {}
            let endingBranches = {}

            sections?.forEach((branch) => {
                if (!branch) {
                    return
                }

                if (branch.Austritt._text) {
                    const dateLeft = new Date(branch.Austritt._text)
                    const lastDayOfMonth = endOfMonth(dateLeft)
                    // const endingDate = formatISO(lastDayOfMonth, { representation: 'date' })
                    const currentDate = new Date()
                    // console.log((lastDayOfMonth < currentDate ? 'EXP' : 'OK ') + ' ->', branch.Name._text, endingDate)

                    if (lastDayOfMonth < currentDate) {
                        return
                    }
                    // } else {
                    //     console.log('NOE ->', branch.Name._text)
                }

                switch (branch.Name._text) {
                    // Adyton cases
                    case 'Fitness':
                        activeBranches = { ...activeBranches, ...{ adyton: true, adytonAddon: 'gold' } }
                        endingBranches = { ...endingBranches, ...{ adyton: branch.Austritt._text } }
                        break
                    case 'Kursangebote': // was 'Kurse'
                        activeBranches = { ...activeBranches, ...{ adyton: true, adytonAddon: 'silber' } }
                        endingBranches = { ...endingBranches, ...{ adyton: branch.Austritt._text } }
                        break
                    case 'Wellness':
                        activeBranches = { ...activeBranches, ...{ adyton: true, adytonAddon: 'bronze' } }
                        endingBranches = { ...endingBranches, ...{ adyton: branch.Austritt._text } }
                        break
                    case 'Baseball':
                        activeBranches = { ...activeBranches, ...{ baseball: true } }
                        endingBranches = { ...endingBranches, ...{ baseball: branch.Austritt._text } }
                        break
                    case 'Basketball':
                        activeBranches = { ...activeBranches, ...{ basketball: true } }
                        endingBranches = {
                            ...endingBranches,
                            ...{ basketball: branch.Austritt._text },
                        }
                        break
                    case 'Fußball':
                        activeBranches = { ...activeBranches, ...{ soccer: true } }
                        endingBranches = { ...endingBranches, ...{ soccer: branch.Austritt._text } }
                        break
                    case 'Fußball - Herren':
                        activeBranches = { ...activeBranches, ...{ soccer: true } }
                        endingBranches = { ...endingBranches, ...{ soccer: branch.Austritt._text } }
                        break
                    case 'Fußball - Jugend':
                        activeBranches = { ...activeBranches, ...{ soccer: true } }
                        endingBranches = { ...endingBranches, ...{ soccer: branch.Austritt._text } }
                        break
                    case 'Handball':
                        activeBranches = { ...activeBranches, ...{ handball: true } }
                        endingBranches = { ...endingBranches, ...{ handball: branch.Austritt._text } }
                        break
                    case 'Judo':
                        activeBranches = { ...activeBranches, ...{ judo: true } }
                        endingBranches = { ...endingBranches, ...{ judo: branch.Austritt._text } }
                        break
                    case 'JuJutsu':
                        activeBranches = { ...activeBranches, ...{ jujutsu: true } }
                        endingBranches = { ...endingBranches, ...{ jujutsu: branch.Austritt._text } }
                        break
                    case 'Karate':
                        activeBranches = { ...activeBranches, ...{ karate: true } }
                        endingBranches = { ...endingBranches, ...{ karate: branch.Austritt._text } }
                        break
                    case 'Leichtathletik':
                        let currentWalking = false
                        if (activeBranches.athleticsAddons && activeBranches.athleticsAddons.walking) {
                            currentWalking = true
                        }
                        activeBranches = {
                            ...activeBranches,
                            ...{ athleticsAddons: { athletics: true, walking: currentWalking } },
                        }
                        endingBranches = {
                            ...endingBranches,
                            ...{ athletics: branch.Austritt._text },
                        }
                        break
                    case 'Le - Walken':
                        let currentAthletics = false
                        if (activeBranches.athleticsAddons && activeBranches.athleticsAddons.athletics) {
                            currentAthletics = true
                        }
                        activeBranches = {
                            ...activeBranches,
                            ...{ athleticsAddons: { athletics: currentAthletics, walking: true } },
                        }
                        endingBranches = {
                            ...endingBranches,
                            ...{
                                walking: branch.Austritt._text,
                            },
                        }
                        break
                    case 'Schach':
                        activeBranches = { ...activeBranches, ...{ chess: true } }
                        endingBranches = { ...endingBranches, ...{ chess: branch.Austritt._text } }
                        break
                    case 'Schwimmen':
                        activeBranches = { ...activeBranches, ...{ swimming: true } }
                        endingBranches = { ...endingBranches, ...{ swimming: branch.Austritt._text } }
                        break
                    case 'Tanz':
                    case 'Tanzsport':
                        // TODO SpartenZusatz: 'Line Dance' == dancingAddon_linedance || SpartenZusatz: 'Paartanz' == dancingAddon_pairdance
                        // activeBranches = { ...activeBranches, ...{ swimming: true } }
                        // endingBranches = { ...endingBranches, ...{ swimming: branch.Austritt._text } }
                        const newActiveBranches: JsonData = {}
                        const newEndingBranches: JsonData = {}

                        if (branch._attributes && branch._attributes.SpartenZusatz) {
                            const danceType = danceTypes[branch._attributes.SpartenZusatz]
                            newActiveBranches[danceType] = true
                            newEndingBranches[danceType] = branch.Austritt._text
                        }

                        activeBranches = { ...activeBranches, ...newActiveBranches }
                        endingBranches = { ...endingBranches, ...newEndingBranches }
                        break
                    case 'TennisSchnupperWinter':
                        activeBranches = {
                            ...activeBranches,
                            ...{ tennis: true, tennisAddonChild: 'schnupperWinter' },
                        }
                        endingBranches = { ...endingBranches, ...{ tennis: branch.Austritt._text } }
                        break
                    case 'TennisSchnupperSommer':
                        activeBranches = {
                            ...activeBranches,
                            ...{ tennis: true, tennisAddonChild: 'schnupperSommer' },
                        }
                        endingBranches = { ...endingBranches, ...{ tennis: branch.Austritt._text } }
                        break
                    case 'Tennis':
                        activeBranches = { ...activeBranches, ...{ tennis: true } }
                        endingBranches = { ...endingBranches, ...{ tennis: branch.Austritt._text } }
                        break
                    case 'Tischtennis':
                        activeBranches = { ...activeBranches, ...{ tableTennis: true } }
                        endingBranches = {
                            ...endingBranches,
                            ...{ tableTennis: branch.Austritt._text },
                        }
                        break
                    case 'Turnen, F.&G.':
                    case 'Turnen, Freizeit, Gesundheitssport':
                        // TODO Spartenzusätze
                        // Werden teilweise zu eigenständigen Sparten in der UI
                        // Spartenzusatz
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        let spartenZusatz: any = { freeAthletics: true }
                        if (branch._attributes && branch._attributes.SpartenZusatz) {
                            switch (branch._attributes.SpartenZusatz) {
                                case 'Rhythmische Sportgymnastik':
                                    spartenZusatz = { sportgymnastik: true }
                                    break
                                case 'Lungensport ohne Verordnung':
                                    spartenZusatz = { lungSport: true }
                                    break
                                case 'Krebsnachsorge ohne Verordnung':
                                    spartenZusatz = { krebsSport: true }
                                    break
                                case 'Rückenfit':
                                    spartenZusatz = { backFit: true }
                                    break
                                case 'Yoga':
                                    spartenZusatz = { yoga: true }
                                    break
                                case 'Osteoporose':
                                    spartenZusatz = { osteoporose: true }
                                    break
                                case 'Eltern-Kind-Turnen (Kind)':
                                    spartenZusatz = { childAthletics: true }
                                    break
                                case 'Trampolin 1. Kind':
                                    spartenZusatz = { trampoline: true, trampolineAddon: 'erstesKind' }
                                    break
                                case 'Trampolin weiteres Kind':
                                    spartenZusatz = { trampoline: true, trampolineAddon: 'weiteresKind' }
                                    break
                                // default is 'Turnen, Freitzeit u. Gesundheit'
                            }
                        }
                        activeBranches = { ...activeBranches, ...spartenZusatz }
                        endingBranches = {
                            ...endingBranches,
                            ...{ freeAthletics: branch.Austritt._text },
                        }
                        break
                    case 'Volleyball':
                        activeBranches = { ...activeBranches, ...{ volleyball: true } }
                        endingBranches = {
                            ...endingBranches,
                            ...{ volleyball: branch.Austritt._text },
                        }
                        break
                    case 'Badminton':
                        activeBranches = { ...activeBranches, ...{ badminton: true } }
                        endingBranches = {
                            ...endingBranches,
                            ...{ badminton: branch.Austritt._text },
                        }
                        break
                    case 'Einrad':
                        // TODO Spartenzusätze
                        activeBranches = { ...activeBranches, ...{ onewheelAddons: true } }
                        endingBranches = {
                            ...endingBranches,
                            ...{ onewheelAddons: branch.Austritt._text },
                        }
                        break
                    case 'Kung Fu':
                        activeBranches = { ...activeBranches, ...{ kungfu: true } }
                        endingBranches = { ...endingBranches, ...{ kungfu: branch.Austritt._text } }
                        break
                    case 'Theater / Laienspiel':
                        activeBranches = { ...activeBranches, ...{ theatre: true } }
                        endingBranches = { ...endingBranches, ...{ theatre: branch.Austritt._text } }
                        break
                    // default:
                    //     console.log('ND  ->', branch.Name._text)
                }
            })

            const sportData: SportDataType = {
                sportData: {
                    ...InitialValuesSportData.sportData,
                    attendee: 'new',
                    // TODO
                    attendeeNew: {
                        firstName: 'Hansi',
                        lastName: 'Müller',
                        dateOfBirth: '01.02.1930',
                        street: 'Hauptstraße',
                        streetNumber: '1',
                        zip: '12345',
                        city: 'Hauptstadt',
                        email: 'user@host.tld',
                        gender: 'weiblich',
                    },
                    ...activeBranches,
                },
                endingData: {
                    ...InitialValuesSportData.endingData,
                    ...endingBranches,
                },
            }

            actions.setSportData(sportData)
        }
    }),
    updateSportData: thunk(async (_actions, payload, { getStoreState, injections }) => {
        const { sportDataService } = injections
        const userEmail = getStoreState().authentication.userEmail
        const userToken = getStoreState().authentication.userToken
        const member = getStoreState().authentication.user?.MemberLoginData.MemberData
        const memberIndex = getStoreState().memberIndexData.index
        // TODO select by laufende nummer
        const memberData = Array.isArray(member) ? member[memberIndex] : member

        if (!memberData) {
            return
        }

        const newMemberData = await sportDataService.updateSportData(payload, memberData, userEmail, userToken)
        return newMemberData
    }),
}

export default sportDataModel
export type { SportDataModel }
