import transformToJson, { ArvResponse, MemberData, RequestState } from 'hooks/transformToJson'

import transformToXml, { AUTH_MODE } from 'hooks/useArvAuth/transformToXml'
import { buildBookGroupXml, leaveBookGroupXml } from 'utils/apiRequests'

const apiUrl = process.env.REACT_APP_API_URL

class GroupsDataApi {
    public getGroupsData = async (user: string, token: string): Promise<ArvResponse> => {
        return new Promise((resolve, reject) => {
            const dataAsXML = transformToXml(AUTH_MODE.GROUPS, user, undefined, token)

            const headers = new Headers()

            fetch(`${apiUrl}`, {
                method: 'POST',
                body: dataAsXML,
                headers,
            })
                .then(this.handleErrors)
                .then((response) => response.text())
                .then((response) => {
                    const arvResponse = transformToJson(response)

                    if (arvResponse.MemberLoginData.RequestState._text === RequestState.SUCCEED) {
                        resolve(arvResponse)
                    }

                    reject(Error(arvResponse.MemberLoginData.Errors?.UserErrMsg?._text))
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    public bookGroupData = async (
        memberData: MemberData,
        groupIndex: string,
        user: string,
        token: string,
    ): Promise<ArvResponse> => {
        return new Promise((resolve, reject) => {
            const dataAsXML = buildBookGroupXml(memberData, groupIndex, user, token)
            const headers = new Headers()

            fetch(`${apiUrl}`, {
                method: 'POST',
                body: dataAsXML,
                headers,
            })
                .then(this.handleErrors)
                .then((response) => response.text())
                .then((response) => {
                    const arvResponse = transformToJson(response)
                    if (arvResponse.MemberLoginData.RequestState._text === RequestState.SUCCEED) {
                        resolve(arvResponse)
                    } else {
                        throw arvResponse
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    public leaveGroupData = async (
        memberData: MemberData,
        groupIndex: string,
        user: string,
        token: string,
    ): Promise<ArvResponse> => {
        return new Promise((resolve, reject) => {
            const dataAsXML = leaveBookGroupXml(memberData, groupIndex, user, token)
            const headers = new Headers()

            fetch(`${apiUrl}`, {
                method: 'POST',
                body: dataAsXML,
                headers,
            })
                .then(this.handleErrors)
                .then((response) => response.text())
                .then((response) => {
                    const arvResponse = transformToJson(response)
                    if (arvResponse.MemberLoginData.RequestState._text === RequestState.SUCCEED) {
                        resolve(arvResponse)
                    } else {
                        throw arvResponse
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    private handleErrors(response: Response): Response {
        if (!response.ok) {
            throw Error(response.statusText)
        }

        return response
    }
}

export default new GroupsDataApi()
