import * as React from 'react'
import styles from 'shared_components/forms.module.scss'
import * as Yup from 'yup'
import { FormMode } from '../../MemberDataTypes'
import { Formik } from 'formik'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { useIsMounted } from 'common'
import ValidationSchemaPaymentData from 'shared_components/FormPaymentData/ValidationSchemaPaymentData'
import FormPaymentData from 'shared_components/FormPaymentData'
import { PaymentDataType } from 'shared_components/FormPaymentData/PaymentDataType'
import SquareCheck from 'common/atoms/Icons/SquareCheck'
import SquareCross from 'common/atoms/Icons/SquareCross'
import { useTypedStoreActions } from 'store/hooks'
import { toast } from 'react-toastify'

interface Props {
    initialValues: PaymentDataType
    mode: FormMode
    changeFormMode: () => void
}

// FEAT show a notification if user is "barzahler" and enters a iban
// In this case the bank data must be validated manually and will be
// available via API later on

const PaymentData = ({ initialValues, mode, changeFormMode }: Props): JSX.Element => {
    const isMounted = useIsMounted()
    const validationSchema = Yup.object().shape(ValidationSchemaPaymentData)
    const updatePaymentData = useTypedStoreActions((state) => state.paymentData.updatePaymentData)
    const updateMemberLoginData = useTypedStoreActions((state) => state.authentication.updateMemberLoginData)

    const onCreate = async (values: PaymentDataType) => {
        try {
            const result = await updatePaymentData(values)
            await updateMemberLoginData(result)
            changeFormMode()
        } catch (err) {
            toast.error(<div data-testid="failureToast">{(err as Error).message}</div>)
        }
    }

    if (mode === 'edit') {
        return (
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={async (values, { setSubmitting }): Promise<void> => {
                    setSubmitting(true)

                    await onCreate(values)

                    if (isMounted()) {
                        setSubmitting(false)
                    }
                }}
            >
                {({ values, handleSubmit, handleReset, isSubmitting }): JSX.Element => (
                    <Form onSubmit={handleSubmit} onReset={handleReset} data-testid="form" className={styles.form}>
                        <FormPaymentData values={values} />
                        <Button type="submit" disabled={isSubmitting} className="float-right rounded-0">
                            Speichern
                        </Button>
                    </Form>
                )}
            </Formik>
        )
    }

    return (
        <div>
            <Row className="pb-4">
                <Col>
                    <Row className="mb-1">
                        <Col xs={12} lg={4} className={styles.label}>
                            IBAN
                        </Col>
                        <Col>{initialValues.paymentData.iban}</Col>
                    </Row>
                    <Row className="mb-1">
                        <Col xs={12} lg={4} className={styles.label}>
                            BIC
                        </Col>
                        <Col>{initialValues.paymentData.bic}</Col>
                    </Row>
                    <Row className="mb-1">
                        <Col xs={12} lg={4} className={styles.label}>
                            Kontoinhaber ist nicht Antragsteller?
                        </Col>
                        <Col>
                            {initialValues.paymentData.hasDifferentAccountOwner ? (
                                <SquareCheck width={16} />
                            ) : (
                                <SquareCross width={16} />
                            )}
                        </Col>
                    </Row>
                    {initialValues.paymentData.hasDifferentAccountOwner && (
                        <>
                            <Row className="mb-1">
                                <Col xs={12} lg={4} className={styles.label}>
                                    Vorname
                                </Col>
                                <Col>{initialValues.paymentData.firstName}</Col>
                            </Row>
                            <Row className="mb-1">
                                <Col xs={12} lg={4} className={styles.label}>
                                    Nachname
                                </Col>
                                <Col>{initialValues.paymentData.lastName}</Col>
                            </Row>
                        </>
                    )}
                    <Row className="mb-1">
                        <Col xs={12} lg={4} className={styles.label}>
                            SEPA-Lastschrift
                        </Col>
                        <Col>
                            {initialValues.paymentData.sepaAccepted ? (
                                <SquareCheck width={16} />
                            ) : (
                                <SquareCross width={16} />
                            )}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}

export default PaymentData
