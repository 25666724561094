import { ArvText, MemberData } from 'hooks/transformToJson'
import { AUTH_MODE } from 'hooks/useArvAuth/transformToXml'
import { PaymentDataType } from 'shared_components/FormPaymentData/PaymentDataType'
import { PersonalDataType } from 'shared_components/FormPersonalData'
import { SportDataType } from 'shared_components/FormSportData'
import * as convert from 'xml-js'

const options = {
    compact: true,
    ignoreComment: true,
    ignoreDeclaration: false,
    spaces: 4,
    attributesKey: '@',
}

const buildEditMemberXml = (
    payload: PersonalDataType,
    memberData: MemberData,
    allMemberData: MemberData | MemberData[] | undefined,
    user: string,
    token: string,
): string => {
    const personalData = payload.personalData

    const fillXmlField = (field: string | undefined) => {
        return field ? { _text: field } : {}
    }

    const getMemberData = () => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const member: any = {
            Writable: {
                Name: fillXmlField(personalData.lastName),
                Vorname: fillXmlField(personalData.firstName),
                Titel: fillXmlField(personalData.title),
                Anrede: {},
                Geburtstag: fillXmlField(personalData.dateOfBirth),
                Geschlecht: fillXmlField(personalData.gender),
                Strasse: fillXmlField(personalData.street),
                Hausnummer: fillXmlField(personalData.streetNumber),
                Co: fillXmlField(personalData.co),
                Plz: fillXmlField(personalData.zip),
                Ort: fillXmlField(personalData.city),
                Telefon: fillXmlField(personalData.phone),
                Mitgliedemail: fillXmlField(user),
                Iban: fillXmlField(memberData.Writable.Iban._text),
                Bic: fillXmlField(memberData.Writable.Bic._text),
                ID_Zahler: fillXmlField(memberData.Writable.ID_Zahler._text),
                Zname: fillXmlField(memberData.Writable.Zname._text),
                Zvorname: fillXmlField(memberData.Writable.Zvorname._text),
                Ztitel: fillXmlField(memberData.Writable.Ztitel._text),
                Zanrede: fillXmlField(memberData.Writable.Zanrede._text),
                Zstrasse: fillXmlField(memberData.Writable.Zstrasse._text),
                Zhausnummer: fillXmlField(memberData.Writable.Zhausnummer._text),
                Zplz: fillXmlField(memberData.Writable.Zplz._text),
                Zort: fillXmlField(memberData.Writable.Zort._text),
                // VereinsAustritt: fillXmlField(memberData.Writable.VereinsAustritt._text),
            },
            Readonly: {
                ID_Mitglied: fillXmlField(memberData.Readonly.ID_Mitglied._text),
                Faelligkeit: fillXmlField(memberData.Readonly.Faelligkeit._text),
                Zahlungsart: fillXmlField(memberData.Readonly.Zahlungsart._text),
                VereinsEintritt: fillXmlField(memberData.Readonly.VereinsEintritt._text),
                Beitraege: {
                    // Beitrag: memberData.Readonly.Beitraege.Beitrag,
                },
            },
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const members: Array<any> = []
        if (Array.isArray(allMemberData)) {
            allMemberData.forEach((element) => {
                if (element.Readonly.ID_Mitglied._text === memberData.Readonly.ID_Mitglied._text) {
                    members.push(member)
                } else {
                    members.push(element)
                }
            })
        } else {
            members.push(member)
        }
        return members
    }

    return convert.js2xml(
        {
            _declaration: {
                // @ts-ignore
                '@': {
                    version: '1.0',
                    encoding: 'utf-8',
                },
            },
            MemberLoginData: {
                RequestState: {},
                Errors: {},
                Mode: {
                    _text: AUTH_MODE.EDIT,
                },
                MemberData: getMemberData(),
                LoginData: {
                    Passwort: {},
                    Token: fillXmlField(token),
                },
            },
        },
        options,
    )
}

export const buildEditPaymentXml = (
    payload: PaymentDataType,
    memberData: MemberData,
    user: string,
    token: string,
): string => {
    const paymentData = payload.paymentData

    const fillXmlField = (field: string | undefined) => {
        return field ? { _text: field } : {}
    }

    return convert.js2xml(
        {
            _declaration: {
                // @ts-ignore
                '@': {
                    version: '1.0',
                    encoding: 'utf-8',
                },
            },
            MemberLoginData: {
                RequestState: {},
                Errors: {},
                Mode: {
                    _text: AUTH_MODE.EDIT,
                },
                MemberData: {
                    Writable: {
                        Name: fillXmlField(memberData.Writable.Name._text),
                        Vorname: fillXmlField(memberData.Writable.Vorname._text),
                        Titel: fillXmlField(memberData.Writable.Titel?._text),
                        Anrede: {},
                        Geburtstag: fillXmlField(memberData.Writable.Geburtstag._text),
                        Geschlecht: fillXmlField(memberData.Writable.Geschlecht._text),
                        Strasse: fillXmlField(memberData.Writable.Strasse._text),
                        Hausnummer: fillXmlField(memberData.Writable.Hausnummer._text?.toString()),
                        Co: fillXmlField(memberData.Writable.Co._text),
                        Plz: fillXmlField(memberData.Writable.Plz._text?.toString()),
                        Ort: fillXmlField(memberData.Writable.Ort._text),
                        Telefon: fillXmlField(memberData.Writable.Telefon._text),
                        Mitgliedemail: fillXmlField(user),
                        Iban: fillXmlField(paymentData.iban),
                        Bic: fillXmlField(paymentData.bic),
                        ID_Zahler: fillXmlField(memberData.Writable.ID_Zahler._text),
                        Zname: fillXmlField(paymentData.lastName),
                        Zvorname: fillXmlField(paymentData.firstName),
                        Ztitel: fillXmlField(memberData.Writable.Ztitel._text),
                        Zanrede: fillXmlField(memberData.Writable.Zanrede._text),
                        Zstrasse: fillXmlField(memberData.Writable.Zstrasse._text),
                        Zhausnummer: fillXmlField(memberData.Writable.Zhausnummer._text),
                        Zplz: fillXmlField(memberData.Writable.Zplz._text),
                        Zort: fillXmlField(memberData.Writable.Zort._text),
                        // VereinsAustritt: fillXmlField(memberData.Writable.VereinsAustritt._text),
                    },
                    Readonly: {
                        ID_Mitglied: fillXmlField(memberData.Readonly.ID_Mitglied._text),
                        Faelligkeit: fillXmlField(memberData.Readonly.Faelligkeit._text),
                        Zahlungsart: fillXmlField(memberData.Readonly.Zahlungsart._text),
                        VereinsEintritt: fillXmlField(memberData.Readonly.VereinsEintritt._text),
                        Beitraege: {
                            // Beitrag: memberData.Readonly.Beitraege.Beitrag,
                        },
                    },
                },
                LoginData: {
                    Passwort: {},
                    Token: fillXmlField(token),
                },
            },
        },
        options,
    )
}

export const buildEditSportXml = (
    payload: SportDataType,
    memberData: MemberData,
    user: string,
    token: string,
): string => {
    const fillXmlField = (field: string | undefined) => {
        return field ? { _text: field } : {}
    }

    const sparten = sportDataToObject(payload, memberData)

    return convert.js2xml(
        {
            _declaration: {
                // @ts-ignore
                '@': {
                    version: '1.0',
                    encoding: 'utf-8',
                },
            },
            MemberLoginData: {
                RequestState: {},
                Errors: {},
                Mode: {
                    _text: AUTH_MODE.EDIT,
                },
                MemberData: {
                    Writable: {
                        Name: fillXmlField(memberData.Writable.Name._text),
                        Vorname: fillXmlField(memberData.Writable.Vorname._text),
                        Titel: fillXmlField(memberData.Writable.Titel?._text),
                        Anrede: {},
                        Geburtstag: fillXmlField(memberData.Writable.Geburtstag._text),
                        Geschlecht: fillXmlField(memberData.Writable.Geschlecht._text),
                        Strasse: fillXmlField(memberData.Writable.Strasse._text),
                        Hausnummer: fillXmlField(memberData.Writable.Hausnummer._text?.toString()),
                        Co: fillXmlField(memberData.Writable.Co._text),
                        Plz: fillXmlField(memberData.Writable.Plz._text?.toString()),
                        Ort: fillXmlField(memberData.Writable.Ort._text),
                        Telefon: fillXmlField(memberData.Writable.Telefon._text),
                        Mitgliedemail: fillXmlField(user),
                        Iban: fillXmlField(memberData.Writable.Iban._text),
                        Bic: fillXmlField(memberData.Writable.Bic._text),
                        ID_Zahler: fillXmlField(memberData.Writable.ID_Zahler._text),
                        Zname: fillXmlField(memberData.Writable.Zname._text),
                        Zvorname: fillXmlField(memberData.Writable.Zvorname._text),
                        Ztitel: fillXmlField(memberData.Writable.Ztitel._text),
                        Zanrede: fillXmlField(memberData.Writable.Zanrede._text),
                        Zstrasse: fillXmlField(memberData.Writable.Zstrasse._text),
                        Zhausnummer: fillXmlField(memberData.Writable.Zhausnummer._text),
                        Zplz: fillXmlField(memberData.Writable.Zplz._text),
                        Zort: fillXmlField(memberData.Writable.Zort._text),
                        // VereinsAustritt: fillXmlField(memberData.Writable.VereinsAustritt._text),
                        Sparten: {
                            Sparte: sparten,
                        },
                    },
                    Readonly: {
                        ID_Mitglied: fillXmlField(memberData.Readonly.ID_Mitglied._text),
                        Faelligkeit: fillXmlField(memberData.Readonly.Faelligkeit._text),
                        Zahlungsart: fillXmlField(memberData.Readonly.Zahlungsart._text),
                        VereinsEintritt: fillXmlField(memberData.Readonly.VereinsEintritt._text),
                        Beitraege: {
                            // Beitrag: memberData.Readonly.Beitraege.Beitrag,
                        },
                    },
                },
                LoginData: {
                    Passwort: {},
                    Token: fillXmlField(token),
                },
            },
        },
        options,
    )
}

export const buildCancelMembershipXml = (
    payload: boolean,
    memberData: MemberData,
    user: string,
    token: string,
): string => {
    const fillXmlField = (field: string | undefined) => {
        return field ? { _text: field } : {}
    }

    return convert.js2xml(
        {
            _declaration: {
                // @ts-ignore
                '@': {
                    version: '1.0',
                    encoding: 'utf-8',
                },
            },
            MemberLoginData: {
                RequestState: {},
                Errors: {},
                Mode: {
                    _text: AUTH_MODE.EDIT,
                },
                MemberData: {
                    Writable: {
                        Name: fillXmlField(memberData.Writable.Name._text),
                        Vorname: fillXmlField(memberData.Writable.Vorname._text),
                        Titel: fillXmlField(memberData.Writable.Titel?._text),
                        Anrede: {},
                        Geburtstag: fillXmlField(memberData.Writable.Geburtstag._text),
                        Geschlecht: fillXmlField(memberData.Writable.Geschlecht._text),
                        Strasse: fillXmlField(memberData.Writable.Strasse._text),
                        Hausnummer: fillXmlField(memberData.Writable.Hausnummer._text?.toString()),
                        Co: fillXmlField(memberData.Writable.Co._text),
                        Plz: fillXmlField(memberData.Writable.Plz._text?.toString()),
                        Ort: fillXmlField(memberData.Writable.Ort._text),
                        Telefon: fillXmlField(memberData.Writable.Telefon._text),
                        Mitgliedemail: fillXmlField(user),
                        Iban: fillXmlField(memberData.Writable.Iban._text),
                        Bic: fillXmlField(memberData.Writable.Bic._text),
                        ID_Zahler: fillXmlField(memberData.Writable.ID_Zahler._text),
                        Zname: fillXmlField(memberData.Writable.Zname._text),
                        Zvorname: fillXmlField(memberData.Writable.Zvorname._text),
                        Ztitel: fillXmlField(memberData.Writable.Ztitel._text),
                        Zanrede: fillXmlField(memberData.Writable.Zanrede._text),
                        Zstrasse: fillXmlField(memberData.Writable.Zstrasse._text),
                        Zhausnummer: fillXmlField(memberData.Writable.Zhausnummer._text),
                        Zplz: fillXmlField(memberData.Writable.Zplz._text),
                        Zort: fillXmlField(memberData.Writable.Zort._text),
                        VereinsAustritt: {
                            '@': { New: payload, Agrund: 1 },
                        },
                    },
                    Readonly: {
                        ID_Mitglied: fillXmlField(memberData.Readonly.ID_Mitglied._text),
                        Faelligkeit: fillXmlField(memberData.Readonly.Faelligkeit._text),
                        Zahlungsart: fillXmlField(memberData.Readonly.Zahlungsart._text),
                        VereinsEintritt: fillXmlField(memberData.Readonly.VereinsEintritt._text),
                        Beitraege: {
                            // Beitrag: memberData.Readonly.Beitraege.Beitrag,
                        },
                    },
                },
                LoginData: {
                    Passwort: {},
                    Token: fillXmlField(token),
                },
            },
        },
        options,
    )
}

export const buildBookGroupXml = (memberData: MemberData, groupIndex: string, user: string, token: string): string => {
    const fillXmlField = (field: string | undefined) => {
        return field ? { _text: field } : {}
    }

    return convert.js2xml(
        {
            _declaration: {
                // @ts-ignore
                '@': {
                    version: '1.0',
                    encoding: 'utf-8',
                },
            },
            MemberLoginData: {
                RequestState: {},
                Errors: {},
                Mode: {
                    _text: AUTH_MODE.BOOK_GROUP,
                },
                MemberData: {
                    Writable: {
                        Name: fillXmlField(memberData.Writable.Name._text),
                        Vorname: fillXmlField(memberData.Writable.Vorname._text),
                        Titel: fillXmlField(memberData.Writable.Titel?._text),
                        Anrede: {},
                        Geburtstag: fillXmlField(memberData.Writable.Geburtstag._text),
                        Geschlecht: fillXmlField(memberData.Writable.Geschlecht._text),
                        Strasse: fillXmlField(memberData.Writable.Strasse._text),
                        Hausnummer: fillXmlField(memberData.Writable.Hausnummer._text?.toString()),
                        Co: fillXmlField(memberData.Writable.Co._text),
                        Plz: fillXmlField(memberData.Writable.Plz._text?.toString()),
                        Ort: fillXmlField(memberData.Writable.Ort._text),
                        Telefon: fillXmlField(memberData.Writable.Telefon._text),
                        Mitgliedemail: fillXmlField(user),
                        Iban: fillXmlField(memberData.Writable.Iban._text),
                        Bic: fillXmlField(memberData.Writable.Bic._text),
                        ID_Zahler: fillXmlField(memberData.Writable.ID_Zahler._text),
                        Zname: fillXmlField(memberData.Writable.Zname._text),
                        Zvorname: fillXmlField(memberData.Writable.Zvorname._text),
                        Ztitel: fillXmlField(memberData.Writable.Ztitel._text),
                        Zanrede: fillXmlField(memberData.Writable.Zanrede._text),
                        Zstrasse: fillXmlField(memberData.Writable.Zstrasse._text),
                        Zhausnummer: fillXmlField(memberData.Writable.Zhausnummer._text),
                        Zplz: fillXmlField(memberData.Writable.Zplz._text),
                        Zort: fillXmlField(memberData.Writable.Zort._text),
                        VereinsAustritt: fillXmlField(memberData.Writable.VereinsAustritt._text),
                        GruppenBuchungen: {
                            Gruppe: {
                                ID: groupIndex,
                                Warteliste: false,
                            },
                        },
                    },
                    Readonly: {
                        ID_Mitglied: fillXmlField(memberData.Readonly.ID_Mitglied._text),
                        Faelligkeit: fillXmlField(memberData.Readonly.Faelligkeit._text),
                        Zahlungsart: fillXmlField(memberData.Readonly.Zahlungsart._text),
                        VereinsEintritt: fillXmlField(memberData.Readonly.VereinsEintritt._text),
                        Beitraege: {
                            // Beitrag: memberData.Readonly.Beitraege.Beitrag,
                        },
                    },
                },
                LoginData: {
                    Passwort: {},
                    Token: fillXmlField(token),
                },
            },
        },
        options,
    )
}

export const leaveBookGroupXml = (memberData: MemberData, groupIndex: string, user: string, token: string): string => {
    const fillXmlField = (field: string | undefined) => {
        return field ? { _text: field } : {}
    }

    return convert.js2xml(
        {
            _declaration: {
                // @ts-ignore
                '@': {
                    version: '1.0',
                    encoding: 'utf-8',
                },
            },
            MemberLoginData: {
                RequestState: {},
                Errors: {},
                Mode: {
                    _text: AUTH_MODE.QUIT_GROUP,
                },
                MemberData: {
                    Writable: {
                        Name: fillXmlField(memberData.Writable.Name._text),
                        Vorname: fillXmlField(memberData.Writable.Vorname._text),
                        Titel: fillXmlField(memberData.Writable.Titel?._text),
                        Anrede: {},
                        Geburtstag: fillXmlField(memberData.Writable.Geburtstag._text),
                        Geschlecht: fillXmlField(memberData.Writable.Geschlecht._text),
                        Strasse: fillXmlField(memberData.Writable.Strasse._text),
                        Hausnummer: fillXmlField(memberData.Writable.Hausnummer._text?.toString()),
                        Co: fillXmlField(memberData.Writable.Co._text),
                        Plz: fillXmlField(memberData.Writable.Plz._text?.toString()),
                        Ort: fillXmlField(memberData.Writable.Ort._text),
                        Telefon: fillXmlField(memberData.Writable.Telefon._text),
                        Mitgliedemail: fillXmlField(user),
                        Iban: fillXmlField(memberData.Writable.Iban._text),
                        Bic: fillXmlField(memberData.Writable.Bic._text),
                        ID_Zahler: fillXmlField(memberData.Writable.ID_Zahler._text),
                        Zname: fillXmlField(memberData.Writable.Zname._text),
                        Zvorname: fillXmlField(memberData.Writable.Zvorname._text),
                        Ztitel: fillXmlField(memberData.Writable.Ztitel._text),
                        Zanrede: fillXmlField(memberData.Writable.Zanrede._text),
                        Zstrasse: fillXmlField(memberData.Writable.Zstrasse._text),
                        Zhausnummer: fillXmlField(memberData.Writable.Zhausnummer._text),
                        Zplz: fillXmlField(memberData.Writable.Zplz._text),
                        Zort: fillXmlField(memberData.Writable.Zort._text),
                        VereinsAustritt: fillXmlField(memberData.Writable.VereinsAustritt._text),
                        GruppenBuchungen: {
                            Gruppe: {
                                ID: groupIndex,
                                Warteliste: false,
                            },
                        },
                    },
                    Readonly: {
                        ID_Mitglied: fillXmlField(memberData.Readonly.ID_Mitglied._text),
                        Faelligkeit: fillXmlField(memberData.Readonly.Faelligkeit._text),
                        Zahlungsart: fillXmlField(memberData.Readonly.Zahlungsart._text),
                        VereinsEintritt: fillXmlField(memberData.Readonly.VereinsEintritt._text),
                        Beitraege: {
                            // Beitrag: memberData.Readonly.Beitraege.Beitrag,
                        },
                    },
                },
                LoginData: {
                    Passwort: {},
                    Token: fillXmlField(token),
                },
            },
        },
        options,
    )
}

export const registerForCourseXml = (
    memberData: MemberData,
    courseData: {
        ID: string
        date: string
    },
    user: string,
    token: string,
): string => {
    const fillXmlField = (field: string | undefined) => {
        return field ? { _text: field } : {}
    }

    return convert.js2xml(
        {
            _declaration: {
                // @ts-ignore
                '@': {
                    version: '1.0',
                    encoding: 'utf-8',
                },
            },
            MemberLoginData: {
                RequestState: {},
                Errors: {},
                Mode: {
                    _text: AUTH_MODE.REGISTER_FOR_COURSES,
                },
                MemberData: {
                    Writable: {
                        Name: fillXmlField(memberData.Writable.Name._text),
                        Vorname: fillXmlField(memberData.Writable.Vorname._text),
                        Titel: fillXmlField(memberData.Writable.Titel?._text),
                        Anrede: {},
                        Geburtstag: fillXmlField(memberData.Writable.Geburtstag._text),
                        Geschlecht: fillXmlField(memberData.Writable.Geschlecht._text),
                        Strasse: fillXmlField(memberData.Writable.Strasse._text),
                        Hausnummer: fillXmlField(memberData.Writable.Hausnummer._text?.toString()),
                        Co: fillXmlField(memberData.Writable.Co._text),
                        Plz: fillXmlField(memberData.Writable.Plz._text?.toString()),
                        Ort: fillXmlField(memberData.Writable.Ort._text),
                        Telefon: fillXmlField(memberData.Writable.Telefon._text),
                        Mitgliedemail: fillXmlField(user),
                        Iban: fillXmlField(memberData.Writable.Iban._text),
                        Bic: fillXmlField(memberData.Writable.Bic._text),
                        ID_Zahler: fillXmlField(memberData.Writable.ID_Zahler._text),
                        Zname: fillXmlField(memberData.Writable.Zname._text),
                        Zvorname: fillXmlField(memberData.Writable.Zvorname._text),
                        Ztitel: fillXmlField(memberData.Writable.Ztitel._text),
                        Zanrede: fillXmlField(memberData.Writable.Zanrede._text),
                        Zstrasse: fillXmlField(memberData.Writable.Zstrasse._text),
                        Zhausnummer: fillXmlField(memberData.Writable.Zhausnummer._text),
                        Zplz: fillXmlField(memberData.Writable.Zplz._text),
                        Zort: fillXmlField(memberData.Writable.Zort._text),
                        VereinsAustritt: fillXmlField(memberData.Writable.VereinsAustritt._text),
                        Kursteilnahme: {
                            Kurstermin: {
                                ID: courseData.ID,
                                Datum: courseData.date,
                            },
                        },
                    },
                    Readonly: {
                        ID_Mitglied: fillXmlField(memberData.Readonly.ID_Mitglied._text),
                        Faelligkeit: fillXmlField(memberData.Readonly.Faelligkeit._text),
                        Zahlungsart: fillXmlField(memberData.Readonly.Zahlungsart._text),
                        VereinsEintritt: fillXmlField(memberData.Readonly.VereinsEintritt._text),
                        Beitraege: {
                            // Beitrag: memberData.Readonly.Beitraege.Beitrag,
                        },
                    },
                },
                LoginData: {
                    Passwort: {},
                    Token: fillXmlField(token),
                },
            },
        },
        options,
    )
}

// TODO
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export const sportDataToObject = (payload: SportDataType, memberData: MemberData): any => {
    if (!payload.sportData) {
        return {}
    }
    if (!memberData.Writable.Geburtstag._text) {
        return {}
    }

    // TODO
    // alte daten und neue vergleichen
    // Soll eine Spartenverminderung stattfinden, muss bei dem entsprechenden Knoten Sparte das Attribut Delete auf true gesetzt werden.
    // Soll eine Spartenerweiterung stattfinden, muss bei ein neuer Knoten Sparte und dem Attribut New ="true" erstellt werden.

    // TODO
    // Schritt 1: Aus den neuen Daten das entsprechende Object machen, damit alles im gleichen Format ist

    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    const newSportData: Array<any> = []

    if (payload.sportData.adyton) {
        if (payload.sportData.adytonAddon === 'bronze') {
            newSportData.push({ '@': { SpartenZusatz: 'Bronze' }, Name: { _text: 'Adyton' } })
        }

        if (payload.sportData.adytonAddon === 'silber') {
            newSportData.push({ '@': { SpartenZusatz: 'Silber' }, Name: { _text: 'Adyton' } })
        }

        if (payload.sportData.adytonAddon === 'gold') {
            newSportData.push({ '@': { SpartenZusatz: 'Gold' }, Name: { _text: 'Adyton' } })
        }
    }

    if (payload.sportData.athleticsAddons && payload.sportData.athleticsAddons.athletics) {
        newSportData.push({ Name: { _text: 'Leichtathletik' } })
    }

    // if (payload.sportData.athleticsAddons && payload.sportData.athleticsAddons.walking) {
    //     newSportData.push({ Name: { _text: 'Le - Walken' } })
    // }

    if (payload.sportData.backFit) {
        newSportData.push({
            '@': { SpartenZusatz: 'Rückenfit' },
            Name: { _text: 'Turnen, Freizeit, Gesundheitssport' },
        })
    }

    if (payload.sportData.badminton) {
        newSportData.push({ Name: { _text: 'Badminton' } })
    }

    if (payload.sportData.baseball) {
        newSportData.push({ Name: { _text: 'Baseball' } })
    }

    if (payload.sportData.basketball) {
        newSportData.push({ Name: { _text: 'Basketball' } })
    }

    if (payload.sportData.chess) {
        newSportData.push({ Name: { _text: 'Schach' } })
    }

    if (payload.sportData.childAthletics) {
        newSportData.push({
            '@': { SpartenZusatz: 'Eltern-Kind-Turnen (Kind)' },
            Name: { _text: 'Turnen, Freizeit, Gesundheitssport' },
        })
    }

    if (payload.sportData.danceAddon_ballett) {
        newSportData.push({ '@': { SpartenZusatz: 'Ballett' }, Name: { _text: 'Tanz' } })
    }

    if (payload.sportData.danceAddon_bellydance) {
        newSportData.push({ '@': { SpartenZusatz: 'Bauchtanz' }, Name: { _text: 'Tanz' } })
    }

    if (payload.sportData.danceAddon_breakdance) {
        newSportData.push({ '@': { SpartenZusatz: 'Breakdance' }, Name: { _text: 'Tanz' } })
    }

    if (payload.sportData.danceAddon_hiphop) {
        newSportData.push({ '@': { SpartenZusatz: 'Hip Hop' }, Name: { _text: 'Tanz' } })
    }

    if (payload.sportData.danceAddon_contemporary) {
        newSportData.push({ '@': { SpartenZusatz: 'Contemporary' }, Name: { _text: 'Tanz' } })
    }

    if (payload.sportData.danceAddon_creativeChildrendance) {
        newSportData.push({ '@': { SpartenZusatz: 'Kreativer Kindertanz' }, Name: { _text: 'Tanz' } })
    }

    if (payload.sportData.danceAddon_linedance) {
        newSportData.push({ '@': { SpartenZusatz: 'Line Dance' }, Name: { _text: 'Tanzsport' } })
    }

    if (payload.sportData.danceAddon_pairdance) {
        newSportData.push({ '@': { SpartenZusatz: 'Paartanz' }, Name: { _text: 'Tanzsport' } })
    }

    if (payload.sportData.danceAddon_tapdance) {
        newSportData.push({ '@': { SpartenZusatz: 'Stepptanz' }, Name: { _text: 'Tanz' } })
    }

    if (payload.sportData.freeAthletics) {
        newSportData.push({ Name: { _text: 'Turnen, Freizeit, Gesundheitssport' } })
    }

    if (payload.sportData.handball) {
        newSportData.push({ Name: { _text: 'Handball' } })
    }

    if (payload.sportData.judo) {
        newSportData.push({ Name: { _text: 'Judo' } })
    }

    if (payload.sportData.jujutsu) {
        newSportData.push({ Name: { _text: 'JuJutsu' } })
    }

    if (payload.sportData.karate) {
        newSportData.push({ Name: { _text: 'Karate' } })
    }

    if (payload.sportData.kungfu) {
        newSportData.push({ Name: { _text: 'Kung Fu' } })
    }

    if (payload.sportData.lungSport) {
        newSportData.push({
            '@': { SpartenZusatz: 'Lungensport ohne Verordnung' },
            Name: { _text: 'Turnen, Freizeit, Gesundheitssport' },
        })
    }

    if (payload.sportData.krebsSport) {
        newSportData.push({
            '@': { SpartenZusatz: 'Krebsnachsorge ohne Verordnung' },
            Name: { _text: 'Turnen, Freizeit, Gesundheitssport' },
        })
    }

    if (payload.sportData.onewheelAddons && payload.sportData.onewheelAddons.hockey) {
        newSportData.push({ '@': { SpartenZusatz: 'Einradhockey' }, Name: { _text: 'Einrad' } })
    }

    if (payload.sportData.onewheelAddons && payload.sportData.onewheelAddons.onewheel) {
        newSportData.push({ '@': { SpartenZusatz: 'Einrad' }, Name: { _text: 'Einrad' } })
    }

    if (payload.sportData.osteoporose) {
        newSportData.push({
            '@': { SpartenZusatz: 'Osteoporose' },
            Name: { _text: 'Turnen, Freizeit, Gesundheitssport' },
        })
    }

    if (payload.sportData.soccer) {
        newSportData.push({ Name: { _text: 'Fußball' } })
    }

    if (payload.sportData.swimming) {
        newSportData.push({ Name: { _text: 'Schwimmen' } })
    }

    if (payload.sportData.tableTennis) {
        newSportData.push({ Name: { _text: 'Tischtennis' } })
    }

    if (payload.sportData.tennis) {
        if (payload.sportData.tennisAddonAdult === 'none' && payload.sportData.tennisAddonChild === 'none') {
            newSportData.push({ Name: { _text: 'Tennis' } })
        }

        if (
            payload.sportData.tennisAddonAdult === 'schnupperSommer' ||
            payload.sportData.tennisAddonChild === 'schnupperSommer'
        ) {
            newSportData.push({ Name: { _text: 'TennisSchnupperSommer' } })
        }

        if (payload.sportData.tennisAddonChild === 'schnupperWinter') {
            newSportData.push({ Name: { _text: 'TennisSchnupperWinter' } })
        }

        if (payload.sportData.tennisAddonAdult === 'wellness') {
            newSportData.push({ Name: { _text: 'Tennis' } })
            newSportData.push({ '@': { SpartenZusatz: 'Wellness' }, Name: { _text: 'Tennis' } })
        }
    }

    if (payload.sportData.theatre) {
        newSportData.push({ Name: { _text: 'Theater / Laienspiel' } })
    }

    if (payload.sportData.trampoline) {
        newSportData.push({
            '@': { SpartenZusatz: 'Trampolinturnen' },
            _text: 'Turnen, Freizeit, Gesundheitssport',
        })
    }

    if (payload.sportData.sportgymnastik) {
        newSportData.push({
            '@': { SpartenZusatz: 'Rhythmische Sportgymnastik' },
            Name: { _text: 'Turnen, Freizeit, Gesundheitssport' },
        })
    }

    if (payload.sportData.volleyball) {
        newSportData.push({ Name: { _text: 'Volleyball' } })
    }

    if (payload.sportData.yoga) {
        newSportData.push({
            '@': { SpartenZusatz: 'Yoga' },
            Name: { _text: 'Turnen, Freizeit, Gesundheitssport' },
        })
    }

    // Schritt 2: Neue und alte Daten vergleichen, welche daten sind neu, welche fallen weg, welche bleiben bestehen?
    // Daten die wegfallen, müssen 1:1 wie bestehend mitgeschickt werden, brauchen aber ein attribute Delete
    // Daten die neu hinzukommen, brauchen keine eintritts und austrittsfelder, aber das attribute New
    // Daten, die sich nicht ändern, wenn mit allen vorhandenen Informationen zurückgeschickt,

    // TODO
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    const updatedSportData: any = []
    type Sparte = {
        Name: ArvText
        Eintritt: ArvText
        Austritt: ArvText
        SpartenID: ArvText
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        _attributes?: any
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        '@'?: any
    }

    // TODO check this completely - looks bonkers, but works (tos)
    const sparten: Sparte[] = memberData.Writable.Sparten
        ? (Array.isArray(memberData.Writable.Sparten?.Sparte) ? memberData.Writable.Sparten?.Sparte : [])
            ? Array.isArray(memberData.Writable.Sparten?.Sparte)
                ? memberData.Writable.Sparten?.Sparte
                : [memberData.Writable.Sparten?.Sparte]
            : []
        : []

    sparten.forEach((sparte) => {
        // delete _attributes if any is there
        if (sparte._attributes) {
            sparte['@'] = sparte._attributes
            sparte._attributes = undefined
            delete sparte._attributes
        }
    })

    sparten.forEach((sparte) => {
        const wasFound = newSportData.find((newSparte) => {
            if (newSparte['@']) {
                return (
                    sparte.Name._text === newSparte.Name._text &&
                    sparte['@'] &&
                    newSparte['@'] &&
                    sparte['@'].SpartenZusatz == newSparte['@'].SpartenZusatz
                )
            }
            return sparte.Name._text === newSparte.Name._text
        })

        // if (!wasFound) {
        //     console.log(wasFound, sparte)
        // }

        if (
            !wasFound &&
            ((sparte.Austritt._text && sparte.Austritt._text?.length === 0) || sparte.Austritt._text === undefined)
        ) {
            // TODO fix this here...
            updatedSportData.push({
                Name: { _text: sparte.Name._text },
                Eintritt: {},
                Austritt: {},
                SpartenID: { _text: sparte.SpartenID._text },
                '@': { Delete: true },
            })
        } else {
            updatedSportData.push(sparte)
        }
    })

    newSportData.forEach((sparte) => {
        const wasFound = sparten.find((oldSparte) => {
            if (sparte['@']) {
                return (
                    sparte.Name._text === oldSparte.Name._text &&
                    sparte['@'] &&
                    oldSparte['@'] &&
                    sparte['@'].SpartenZusatz == oldSparte['@'].SpartenZusatz
                )
            }
            return sparte.Name._text === oldSparte.Name._text
        })

        if (!wasFound) {
            updatedSportData.push({
                Name: { _text: sparte.Name._text },
                Eintritt: {},
                Austritt: {},
                '@': { New: true, ...sparte['@'] },
            })
        }
    })

    if (updatedSportData.length === 0) {
        updatedSportData.push({ Name: { _text: 'Spartenfrei' } })
    }

    return updatedSportData
}

export default buildEditMemberXml
