import transformToJson, { ArvResponse, MemberData, RequestState } from 'hooks/transformToJson'
import { PersonalDataType } from 'shared_components/FormPersonalData'
import buildEditMemberXml from 'utils/apiRequests'

const apiUrl = process.env.REACT_APP_API_URL

class PersonalDataApi {
    public updatePersonalData = async (
        personalData: PersonalDataType,
        memberData: MemberData,
        allMemberData: MemberData | MemberData[] | undefined,
        user: string,
        token: string,
    ): Promise<ArvResponse> => {
        return new Promise((resolve, reject) => {
            const dataAsXML = buildEditMemberXml(personalData, memberData, allMemberData, user, token)
            const headers = new Headers()
            // console.log(memberData)
            // console.log(dataAsXML)

            fetch(`${apiUrl}`, {
                method: 'POST',
                body: dataAsXML,
                headers,
            })
                .then(this.handleErrors)
                .then((response) => response.text())
                .then((response) => {
                    const arvResponse = transformToJson(response)
                    if (arvResponse.MemberLoginData.RequestState._text === RequestState.SUCCEED) {
                        resolve(arvResponse)
                    }

                    reject(Error(arvResponse.MemberLoginData.Errors?.UserErrMsg?._text))
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    private handleErrors(response: Response): Response {
        if (!response.ok) {
            throw Error(response.statusText)
        }

        return response
    }
}

export default new PersonalDataApi()
