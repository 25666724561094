import { ArvResponse, MemberData } from 'hooks/transformToJson'
import { PaymentDataType } from 'shared_components/FormPaymentData/PaymentDataType'
import PaymentDataApi from './PaymentDataApi'

export interface PaymentDataServiceInterface {
    updatePaymentData: (
        paymentData: PaymentDataType,
        memberData: MemberData,
        user: string,
        token: string,
    ) => Promise<ArvResponse>
}

class paymentDataService {
    public updatePaymentData = async (
        paymentData: PaymentDataType,
        memberData: MemberData,
        user: string,
        token: string,
    ): Promise<ArvResponse> => {
        return await PaymentDataApi.updatePaymentData(paymentData, memberData, user, token)
    }
}

export default new paymentDataService()
