import { H3 } from 'common'
import { MemberData } from 'hooks/transformToJson'
import * as React from 'react'
import { Card, Col, Row, Table } from 'react-bootstrap'
import { renderDateFromString } from 'utils/date'
import { BillingData } from '../../MemberDataTypes'

import styles from './ActiveMembershipTable.module.scss'

export interface Props {
    billingsData?: BillingData | null
    memberData?: MemberData | MemberData[] | undefined
}

const ActiveMembershipTable = ({ billingsData, memberData }: Props): JSX.Element => {
    const [summary, setSummary] = React.useState<number>(-1)
    const [summaryFamily, setSummaryFamily] = React.useState<number>(0)

    if (!billingsData || !billingsData.Beitraege.Beitrag.length) {
        return <></>
    }

    const rederFees = (): JSX.Element[] => {
        return billingsData?.Beitraege.Beitrag.map((fee) => {
            const currentFee = !fee.Betrag?._text ? 0 : parseFloat(fee.Betrag?._text)

            if (fee.Name._text?.includes('Hauptverein')) {
                return (
                    <tr key={fee.Name._text?.toLowerCase()}>
                        <th colSpan={2} className="border-top-0">
                            Vereinsbeitrag
                        </th>
                        <td className="text-right border-top-0">
                            {currentFee.toLocaleString('de-DE', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}
                        </td>
                        <td className="border-top-0">€</td>
                    </tr>
                )
            }

            return (
                <tr key={fee.Name._text?.toLowerCase()}>
                    <td className="text-right">+</td>
                    <td>{fee.Name._text}</td>
                    <td className="text-right">
                        {currentFee.toLocaleString('de-DE', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        })}
                    </td>
                    <td>€</td>
                </tr>
            )
        })
    }

    React.useEffect((): void => {
        let feeSummary = 0
        let familySummary = 0

        billingsData?.Beitraege.Beitrag.forEach((fee) => {
            const currentFee = !fee.Betrag?._text ? 0 : parseFloat(fee.Betrag?._text)
            feeSummary += currentFee
        })
        setSummary(feeSummary)

        if (Array.isArray(memberData)) {
            memberData.forEach((member) => {
                if (member.Readonly.Beitraege && member.Readonly.Beitraege.Beitrag) {
                    let beitraege = member.Readonly.Beitraege.Beitrag
                    if (!Array.isArray(beitraege)) beitraege = [beitraege]
                    beitraege.forEach((fee) => {
                        const currentFee = !fee.Betrag?._text ? 0 : parseFloat(fee.Betrag?._text)
                        familySummary += currentFee
                    })
                }
            })
            setSummaryFamily(familySummary)
        } else {
            setSummaryFamily(-1)
        }
    }, [billingsData])

    return (
        <>
            <H3>monatliche Mitgliedsbeiträge</H3>
            <Row>
                <Col>
                    <Card className={styles.billWrapper}>
                        <Card.Body className="p-0">
                            <Table responsive>
                                <tbody>
                                    {rederFees()}
                                    <tr>
                                        <th colSpan={2}>Summe Mitglied</th>
                                        <th className="text-right">
                                            {summary.toLocaleString('de-DE', {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                            })}
                                        </th>
                                        <th>€</th>
                                    </tr>
                                    {summaryFamily >= 0 && (
                                        <tr>
                                            <th colSpan={2}>Einzug gesamt</th>
                                            <th className="text-right">
                                                {summaryFamily.toLocaleString('de-DE', {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                })}
                                            </th>
                                            <th>€</th>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card className="border-0">
                        <Card.Body>
                            <Table responsive>
                                <tbody>
                                    <tr>
                                        <td>Vereinsbeitritt</td>
                                        <td>{renderDateFromString(billingsData?.VereinsEintritt._text)}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <p>
                Die Abbuchung erfolgt quartalsweise für 3 Monatsbeiträge jeweils am 10. Bankarbeitstag zu Beginn des
                ersten Monats eines Quartals. Ausnahme: Beiträge des ADYTON - dieser erfolgt monatlich. Rückwirkende
                Beiträge sind am 10. Bankarbeitstag des Folgemonats fällig.
            </p>
            <p>
                Die Aufnahmegebühr sowie sonstige Gebühren (Fitnesscheck, Tennisaufnahme etc) sind mit dem ersten
                Beitragseinzug fällig. Die jeweilige Höhe des Beitrags, der Aufnahmegebühr und sonstige Gebühren werden
                nach der im Sportprogramm enthaltenen Beitrags- und Gebührenauflistung (
                <a
                    href="https://www.niendorfer-tsv.de/mitgliedschaft/beitraege.html"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    mtl. Beiträge
                </a>
                ,{' '}
                <a
                    href="https://www.niendorfer-tsv.de/mitgliedschaft/zusatzbeitraege-allgemein.html"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Zusatzbeiträge
                </a>
                ) geregelt.
            </p>
        </>
    )
}

export default ActiveMembershipTable
