import { H2 } from 'common'
import TriangleIcon from 'common/atoms/Icons/TriangleIcon'
import * as React from 'react'
import { useContext } from 'react'
import { Accordion, AccordionContext, Col, Row, useAccordionToggle } from 'react-bootstrap'
import styles from './AccordionToggle.module.scss'

export interface Props {
    headline: string
    subline?: string
    eventKey: string
    isDefault?: boolean
    callback?: (eventKey: string) => void
}
const AccordionToggle = ({ headline, subline, eventKey, isDefault, callback }: Props): JSX.Element => {
    const currentEventKey = useContext(AccordionContext)
    const [toggleIconClass, setToggleIconClass] = React.useState<string>(styles.toggleIcon)
    const isCurrentEventKey = currentEventKey === eventKey

    const handleSelect = useAccordionToggle(eventKey, () => {
        callback && callback(eventKey)
    })

    React.useEffect((): void => {
        if (isDefault) {
            setToggleIconClass(styles.toggleIconActive)
        }
    }, [isDefault])

    React.useEffect((): void => {
        setToggleIconClass(isCurrentEventKey ? styles.toggleIcon : styles.toggleIconActive)
    }, [currentEventKey])

    return (
        <Accordion.Toggle className={styles.accordionHeader} onClick={handleSelect} eventKey={eventKey}>
            <Row>
                <Col>
                    <H2 className="m-0">
                        {headline}
                        {subline && <div className={styles.subline}>{subline}</div>}
                    </H2>
                </Col>
                <Col xs={1} className="text-right">
                    <span className={toggleIconClass}>
                        <TriangleIcon width={22} />
                    </span>
                </Col>
            </Row>
        </Accordion.Toggle>
    )
}

export default AccordionToggle
