import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import ProtectedRoute from 'components/ProtectedRoute/ProtectedRoute'
import { UserRole } from 'types/user'
import LoginPage from 'pages/public/LoginPage'
import PageHeader from 'shared_components/PageHeader'
import PageFooter from 'shared_components/PageFooter'
import MemberData from 'pages/protected/MemberData'
import { useTypedStoreState } from 'store/hooks'

export default function App(): JSX.Element {
    const isAuthenticated = useTypedStoreState((state) => state.authentication.isAuthenticated)

    return (
        <Router basename={process.env.REACT_APP_BASE_URL}>
            <div data-testid="wrapper">
                <PageHeader />

                {/* A <Switch> looks through its children <Route>s and
              renders the first one that matches the current URL. */}
                <Switch>
                    <ProtectedRoute path="/personal-data" roles={[UserRole.ADMIN]} component={MemberData} />
                    <Route exact path="/">
                        {isAuthenticated ? <Redirect to="/personal-data" /> : <LoginPage />}
                    </Route>
                    <Route path="*">
                        <Redirect to="/" />
                    </Route>
                </Switch>
                <PageFooter />
            </div>
        </Router>
    )
}
