import { Formik } from 'formik'
import { toast } from 'react-toastify'
import { Button, Col, Form, Row } from 'react-bootstrap'
import * as Yup from 'yup'
import useArvAuth from 'hooks/useArvAuth'
import { FormInputLabeled, PasswordInput, useIsMounted } from 'common'
import styles from 'shared_components/forms.module.scss'

type PasswordChangeData = {
    oldPassword: string
    newPassword: string
}

export interface Props {
    userToken?: string
}

const ChangePassword = ({ userToken }: Props): JSX.Element => {
    const { changePassword } = useArvAuth()
    const isMounted = useIsMounted()
    const initialValues: PasswordChangeData = { oldPassword: '', newPassword: '' }
    const validationSchema = Yup.object({
        oldPassword: Yup.string().required('Bitte ein Passwort eingeben'),
        newPassword: Yup.string().required('Bitte ein Passwort eingeben'),
        newPasswordRepeat: Yup.string().oneOf([Yup.ref('newPassword'), null], 'Passwörter müssen übereinstimmen'),
    })

    if (!userToken) {
        return <></>
    }

    const onUpdate = async (values: PasswordChangeData) => {
        try {
            await changePassword(values.newPassword, userToken)
        } catch (err) {
            toast.error(<div data-testid="failureToast">{(err as Error).message}</div>)
        }
    }

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={async (values, { setSubmitting }): Promise<void> => {
                    setSubmitting(true)

                    await onUpdate(values)

                    if (isMounted()) {
                        setSubmitting(false)
                    }
                }}
            >
                {({ isSubmitting, handleSubmit, handleReset, values }): JSX.Element => (
                    <Form onSubmit={handleSubmit} onReset={handleReset} data-testid="form" className={styles.form}>
                        <Row>
                            <Col lg={6}>
                                <FormInputLabeled
                                    label="Altes Passwort"
                                    controlId="oldPassword"
                                    labelColumnSize={4}
                                    inputComponent={
                                        <PasswordInput
                                            name="oldPassword"
                                            value={values.oldPassword}
                                            className="oldPassword mb-3"
                                        />
                                    }
                                />
                                <FormInputLabeled
                                    label="Neues Passwort"
                                    controlId="newPassword"
                                    labelColumnSize={4}
                                    inputComponent={
                                        <PasswordInput
                                            name="newPassword"
                                            value={values.newPassword}
                                            className="newPassword mb-3"
                                        />
                                    }
                                />
                            </Col>
                            <Col lg={6}>
                                <FormInputLabeled
                                    label="Neues Passwort wiederholen"
                                    controlId="newPasswordRepeat"
                                    labelColumnSize={4}
                                    inputComponent={
                                        <PasswordInput
                                            name="newPasswordRepeat"
                                            value=""
                                            className="newPasswordRepeat mb-3"
                                        />
                                    }
                                />
                            </Col>
                        </Row>
                        <div className="text-right mt-3">
                            <Button className="rounded-0" type="submit" disabled={isSubmitting}>
                                Speichern
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default ChangePassword
