import { H2, H3, InfoIcon } from 'common'
import * as React from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import FormLogin from './components/FormLogin'
import FormRegister from './components/FormRegister'
import LostPasswordModal from './components/LostPasswordModal'

import styles from './Login.module.scss'

const LoginPage = (): JSX.Element => {
    const [lostPasswordModalVisible, setLostPasswordModalVisible] = React.useState(false)
    const handleCloseModal = () => setLostPasswordModalVisible(false)
    const handleShowModal = () => setLostPasswordModalVisible(true)

    return (
        <Container>
            <H2 className={styles.h1}>Mein Niendorfer TSV</H2>
            <Row>
                <Col>
                    <Card bg="primary" text="white" className="mb-4">
                        <Card.Body>
                            <FormLogin handleShowModal={handleShowModal} />
                            <LostPasswordModal visible={lostPasswordModalVisible} handleClose={handleCloseModal} />
                        </Card.Body>
                    </Card>

                    <Card className="mb-4">
                        <Card.Body>
                            <H3>
                                Was ist Mein NTSV? <InfoIcon />
                            </H3>
                            <p>
                                Es handelt sich um die digitale Verwaltung der Vereinsmitgliedschaft. So bieten wir
                                Ihnen die Möglichkeit wichtige Daten wie Anschrift, Kontaktdaten oder auch
                                Bankverbindungen online zu verwalten und zu aktualisieren. Ebenfalls können
                                Spartenveränderungen (Spartenerweiterungen oder auch Kündigungen) mit den geltenden
                                Bedingungen unserer Satzung bequem online getätigt werden.
                            </p>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card>
                        <Card.Body>
                            <H2 className="mt-0">Jetzt registrieren</H2>
                            <p>
                                Bitte registrieren Sie sich mit der E-Mailadresse, die Sie bei Ihrem Vereinsantritt
                                angegeben haben. <InfoIcon />
                            </p>
                            <FormRegister />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default LoginPage
