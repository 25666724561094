import { action, thunk } from 'easy-peasy'
import { PersonalDataType } from 'shared_components/FormPersonalData'
import { PersonalDataModel } from './index.interface'

const personalDataModel: PersonalDataModel = {
    personalData: null,
    setPersonalData: action((state, payload) => {
        state.personalData = payload
    }),
    getPersonalData: thunk(async (actions, _payload, { getStoreState }) => {
        const userData = getStoreState().authentication.user
        const userEmail = getStoreState().authentication.userEmail
        const memberIndex = getStoreState().memberIndexData.index

        if (userData?.MemberLoginData.MemberData) {
            const memberData = Array.isArray(userData?.MemberLoginData.MemberData)
                ? userData?.MemberLoginData.MemberData[memberIndex]
                : userData?.MemberLoginData.MemberData

            const personalData: PersonalDataType = {
                personalData: {
                    ntsvMemberId: memberData.Readonly.MitgliedsNr._text?.toString() || '',
                    ntsvLfdNumber: memberData.Readonly.LfdNr._text?.toString() || '',
                    memberId: memberData.Readonly.ID_Mitglied._text || '',
                    title: memberData.Writable.Titel?._text || '',
                    lastName: memberData.Writable.Name._text || '',
                    firstName: memberData.Writable.Vorname._text || '',
                    street: memberData.Writable.Strasse._text || '',
                    streetNumber: memberData.Writable.Hausnummer._text?.toString() || '',
                    zip: memberData.Writable.Plz._text?.toString() || '',
                    city: memberData.Writable.Ort._text || '',
                    dateOfBirth: memberData.Writable.Geburtstag._text || '',
                    gender: memberData.Writable.Geschlecht._text || '',
                    phone: memberData.Writable.Telefon._text || '',
                    email: userEmail || '',
                    co: memberData.Writable.Co._text || '',
                    cardURL: memberData.Readonly.DigitalerAusweis._text || '',
                },
            }

            actions.setPersonalData(personalData)
        }
    }),
    updatePersonalData: thunk(async (_actions, payload, { getStoreState, injections }) => {
        const { personalDataService } = injections
        const userEmail = getStoreState().authentication.userEmail
        const userToken = getStoreState().authentication.userToken
        const member = getStoreState().authentication.user?.MemberLoginData.MemberData
        const memberIndex = getStoreState().memberIndexData.index
        // TODO select by laufende nummer
        const memberData = Array.isArray(member) ? member[memberIndex] : member

        if (!memberData) {
            return
        }

        const newMemberData = await personalDataService.updatePersonalData(
            payload,
            memberData,
            member,
            userEmail,
            userToken,
        )
        return newMemberData
    }),
    getPersonalNames: thunk((actions, _payload, { getStoreState }) => {
        const userData = getStoreState().authentication.user
        const nameData: string[] = []
        if (Array.isArray(userData?.MemberLoginData.MemberData)) {
            userData?.MemberLoginData.MemberData.forEach((value) => {
                nameData.push((value.Writable.Vorname._text as string) + ' ' + (value.Writable.Name._text as string))
            })
        } else {
            const value = userData?.MemberLoginData.MemberData
            if (value) {
                nameData.push((value.Writable.Vorname._text as string) + ' ' + (value.Writable.Name._text as string))
            }
        }
        return nameData
    }),
}

export default personalDataModel
export type { PersonalDataModel }
