import authenticationModel, { AuthenticationModel } from './authentication'
import billingDataModel, { BillingDataModel } from './billingData'
import memberIndexModel, { MemberIndexModel } from './memberindex'
import membershipDataModel, { MembershipDataModel } from './membershipData'
import paymentDataModel, { PaymentDataModel } from './paymentData'
import personalDataModel, { PersonalDataModel } from './personalData'
import sportDataModel, { SportDataModel } from './sportData'
import groupDataModel, { GroupDataModel } from './groupData'
import courseDataModel, { CourseDataModel } from './courseData'
import bookGroupIndexModel, { BookGroupIndexModel } from './bookGroupIndex'

export interface StoreModel {
    authentication: AuthenticationModel
    personalData: PersonalDataModel
    paymentData: PaymentDataModel
    sportData: SportDataModel
    groupData: GroupDataModel
    bookGroupIndex: BookGroupIndexModel
    courseData: CourseDataModel
    billingData: BillingDataModel
    memberIndexData: MemberIndexModel
    membershipData: MembershipDataModel
}

const storeModel: StoreModel = {
    authentication: authenticationModel,
    personalData: personalDataModel,
    paymentData: paymentDataModel,
    sportData: sportDataModel,
    groupData: groupDataModel,
    courseData: courseDataModel,
    bookGroupIndex: bookGroupIndexModel,
    billingData: billingDataModel,
    memberIndexData: memberIndexModel,
    membershipData: membershipDataModel,
}

export default storeModel
